<!--  -->
<template>
  <drawerDialog
    :visible.sync="visible"
    @handleClose="handleClose"
    title="阈值"
    size="80%"
  >
    <div class="flex">
   
      <el-tree
        class="treeBox"
        :data="data"
        ref="treeRef"
        show-checkbox
        @check-change="handleCheckChange"
        default-expand-all
        node-key="nodeKye"
        :indent="8"
      ></el-tree>
      <div style="width: 40%; overflow-y: auto">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :validate-on-rule-change="false"
          :show-message="false"
          label-width="90px"
        >
          <el-form-item label="报警名称" prop="label">
            <el-input
              v-model.trim="form.label"
              placeholder="请输入报警名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="报警级别" prop="level">
            <el-select
              v-model.number="form.level"
              placeholder="请选择报警级别"
              style="width: 100%"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in levelDict"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="c-el-option">
                  <div class="el-select-dropdown__item_label">
                    {{ item.label }}
                  </div>
                  <div class="el-select-dropdown__item_describe">
                    {{ item.describe }}
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-table
          :data="form.condition"
          style="width: 100%; min-width: 500px"
          empty-text="请先勾选阈值项"
        >
          <el-table-column prop="object" label="来源" width="180">
            <template slot-scope="scope">
              <span>{{ getObjectDescription(scope.row.object) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="property" label="属性" width="80">
            <template slot-scope="scope">
              <span>{{ formatPropertyValue(scope.row.property) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operator" label="条件">
            <template slot-scope="scope">
              <el-select v-model="scope.row.operator" placeholder="请选择条件">
                <el-option
                  v-for="item in operatorOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="value" label="值">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model.number="scope.row.value"
                placeholder="请输入值"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                style="color: #f56c6c"
                size="mini"
                type="text"
                icon="el-icon-close"
                @click="onDel(scope.row, scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        
        <div style="margin-top: 10px" v-if="form.condition.length">
          <el-button @click="onAdd">保存</el-button>
        </div>
      </div>
      <div class="warnbox" >
      <div v-for="(item,index) in tableWarn" :key="index">
        <div>{{ item.label }}</div>
        <div>告警级别{{ item.level }}</div>
        <el-table
          :data="item.condition"
          style=" min-width: 500px;margin-bottom: 20px;"
         
        >
          <el-table-column prop="object" label="来源" >
            <template slot-scope="scope">
              <span>{{ getObjectDescription(scope.row.object) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="property" label="属性" width="80">
            <template slot-scope="scope">
              <span>{{ formatPropertyValue(scope.row.property) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operator" label="条件">
            <template slot-scope="scope">
               <!-- <el-select v-model="scope.row.operator" placeholder="请选择条件">
                <el-option
                  v-for="item in operatorOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> -->
              <span v-if="scope.row.operator =='>'">大于</span>
              <span v-if="scope.row.operator=='<'">小于</span>
              <span v-if="scope.row.operator=='>='">大于等于</span>
              <span v-if="scope.row.operator=='<='">小于等于</span>
             
            </template>
          </el-table-column>
          <el-table-column prop="value" label="值">
            <!-- <template slot-scope="scope">
              <el-input
                type="number"
                v-model.number="scope.row.value"
                placeholder="请输入值"
              ></el-input>
            </template> -->
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                style="color: #f56c6c"
                size="mini"
                type="text"
                icon="el-icon-close"
                @click="onDels(tableWarn[index], scope.$index,scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    </div>
  </drawerDialog>
</template>

<script>
import drawerDialog from "../../../components/common/drawerDialog";
import { createConnect, endConnect } from "../../../api/mqtt";
import mqtt from "mqtt";
import { demandPublish } from "../../../utils/index";
import screen from "../../../api/modules/screen";
export default {
  props: ["visible", "title", "realTimeData"],
  components: {
    drawerDialog,
  },
  data() {
    return {
      tableWarn:[],
      data: [
        {
          label: "通用",

          object: "generic",
          nodeKye: "generic",
          children: [
            {
              label: "风速",
              property: "wind",
              object: "generic",
              nodeKye: "generic.wind",
            },
          ],
        },
        {
          label: "塔身",
          object: "tower",
          nodeKye: "tower",
          children: [
            {
              label: "变幅",
              object: "tower.gantry",
              nodeKye: "tower.gantry",
              children: [
                {
                  label: "起升",
                  nodeKye: "tower.gantry.hoist",
                  object: "tower.gantry.hoist",
                  children: [
                    {
                      label: "制动次数",
                      property: "brakecount",
                      object: "tower.gantry.hoist",
                      nodeKye: "tower.gantry.hoist.brakecount",
                    },
                    {
                      label: "高度",
                      property: "height",
                      object: "tower.gantry.hoist",
                      nodeKye: "tower.gantry.hoist.height",
                    },
                    {
                      label: "溜钩",
                      property: "slide",
                      object: "tower.gantry.hoist",
                      nodeKye: "tower.gantry.hoist.slide",
                    },
                    {
                      label: "溜钩时间",
                      property: "slidetime",
                      object: "tower.gantry.hoist",
                      nodeKye: "tower.gantry.hoist.slidetime",
                    },
                    {
                      label: "速度",
                      property: "speed",
                      object: "tower.gantry.hoist",
                      nodeKye: "tower.gantry.hoist.speed",
                    },
                    {
                      label: "重量",
                      property: "weight",
                      object: "tower.gantry.hoist",
                      nodeKye: "tower.gantry.hoist.weight",
                    },
                  ],
                },
                {
                  label: "力矩",
                  property: "moment",
                  object: "tower.gantry",
                  nodeKye: "tower.gantry.moment",
                },
                {
                  label: "力矩比",
                  property: "momentratio",
                  object: "tower.gantry",
                  nodeKye: "tower.gantry.momentratio",
                },
                {
                  label: "速度",
                  property: "speed",
                  object: "tower.gantry",
                  nodeKye: "tower.gantry.speed",
                },
                {
                  label: "行程",
                  property: "travel",
                  object: "tower.gantry",
                  nodeKye: "tower.gantry.travel",
                },
              ],
            },
            {
              label: "水平",
              property: "horizontal",
              object: "tower",
              nodeKye: "tower.horizontal",
            },
            {
              label: "回转",
              property: "rotation",
              object: "tower",
              nodeKye: "tower.rotation",
            },
            {
              label: "速度",
              property: "speed",
              object: "tower",
              nodeKye: "tower.speed",
            },
            {
              label: "垂直",
              property: "vertical",
              object: "tower",
              nodeKye: "tower.vertical",
            },
          ],
        },
      ],
      record:[],
      defaultProps: {
        children: "children",
        label: "label",
      },
      gridData: [],
      calibrationList: [],
      checkedNodes: [], // 用于存储选中的节点
      form: {
        label: null,
        level: null,
        condition: [],
      },
      levelDict: [
        {
            label:'常规提示',
            value:1,
            describe:'终端文字提示'
        },
        {
            label:'预警提示',
            value:11,
            describe:'终端弹窗提示和语音告警'
        },
        {
            label:'普通告警',
            value:21,
            describe:'终端弹窗提示和语音告警，平台告警提示'
        },
        {
            label:'严重告警',
            value:51,
            describe:'终端弹窗提示和语音告警，平台全域告警提示并进行短信推送提醒'
        },
        {
            label:'致命告警',
            value:81,
            describe:'终端弹窗提示和语音告警，平台全域红色告警提示并进行短信推送提醒'
        },
      ],
      operatorOptions: [
        {
          label: "大于",
          value: ">",
        },
        {
          label: "小于",
          value: "<",
        },
        {
          label: "大于等于",
          value: ">=",
        },
        {
          label: "小于等于",
          value: "<=",
        },
      ],
      currentSelectObject: [],
      rules: {
        label: [
          {
            required: true,
            message: "请输入报警名称",
            // 可以单个或者同时写两个触发验证方式
          },
        ],
        level: [
          {
            required: true,
            type: "number",
            min: 1,
            message: "请输入报警级别",
          },
        ],
      },
    };
  },
  mounted() {
    this.initData()
  },
  watch: {
    realTimeData(val) {
      // this.initData()
    },
    visible(val) {
      this.initData()
    },
  },
  methods: {
    initData(){
    
      
      let params = {
        deviceImei: this.title,
        termTopic: "Threshold",
      };
      screen.getTerm(params).then((res) => {
        if (res.data.length > 0) {
          let data = JSON.parse(res.data[0]?.termData);
   
          this.tableWarn = data.threshold
        // this.statusDatas = data.status[0].status
        
        }
      });
    },
    getObjectDescription(objectValue) {
      if (objectValue === "generic") {
        return "通用";
      } else if (objectValue === "tower.gantry.hoist") {
        return "塔身变幅起升";
      } else if (objectValue === "tower.gantry") {
        return "塔身变幅";
      } else if (objectValue === "tower") {
        return "塔身";
      }
    },
    formatPropertyValue(propertyValue) {
      const propertyDescriptions = {
        wind: "风速",
        brakecount: "制动次数",
        height: "高度",
        slide: "溜钩",
        slidetime: "溜钩时间",
        speed: "速度",
        weight: "重量",
        moment: "力矩",
        momentratio: "力矩比",
        travel: "行程",
        horizontal: "水平",
        rotation: "回转",
        vertical: "垂直",

        // 可以根据需要添加更多的映射关系
      };
      return propertyDescriptions[propertyValue] || propertyValue;
    },
    handleClose() {
      this.$emit("handleClose");
    },
    getPath(node, path = []) {
      if (!node) return path;
      path.unshift(node.label); // 将当前节点的标签添加到路径数组的开头
      // 因为Element UI的el-tree不提供parent属性，我们需要自己查找父节点
      let parentNode = this.data.find(
        (p) => p.children && p.children.some((child) => child === node)
      );
      if (parentNode) {
        return this.getPath(parentNode, path); // 递归调用，继续查找父节点
      }
      return path;
    },
    onDel(item, index) {
      console.log("item", item);

      this.form.condition.splice(index, 1);
      this.$refs.treeRef.setChecked(`${item.object}.${item.property}`, false);
    },
    async onDels(calibrationObject, pointIndex,items) {
      try {
        // await this.$confirm('确定要删除吗?');
      } catch (error) {
        return;
      }
      return await this.delSubmit(calibrationObject, pointIndex,items);
  
},
 async delSubmit(item, index,items) {
  
  let supplyData = {};
      if (item.condition.length > 1) {
        supplyData.command = 'WriteThreshold';
        let condition = this.info.condition.filter((_item) => {
          return _item != item;
        });
        supplyData.threshold = [
          {
            ...item,
            condition: condition
          }
        ];
      } else {
        return this.clearSubmit(item);
      }
      this.mqttSubmit = demandPublish(this.title, supplyData);
      let loading = this.$loading();
      let res = await this.mqttSubmit.publish();
      loading.close();
      if (res.command != 'Succeed') {
        this.$message(res.command == 'timeout' ? '请求超时，请稍后重试。' : '删除失败');
        return res;
      }
      this.$message('删除成功');
      item.condition.splice(index, 1);
      return res;
    },
       // 清除阈值
       async clearSubmit(item) {
      let supplyData = {
        command: 'DeleteThreshold',
        threshold: [
          {
            label:item.label
          }
        ]
      };
      let loading = this.$loading();
      this.mqttSubmit = demandPublish(this.title, supplyData);
      let res = await this.mqttSubmit.publish();
      loading.close();
      if (res.command != 'Succeed') {
        this.$message(res.command == 'timeout' ? '请求超时，请稍后重试。' : '删除失败');
        return res;
      }
      this.$message('删除成功');
      this.initData()
      // this.$emit('del', this.info);
      return res;
    },
    handleCheckChange(item, isSelect, indeterminate) {
      // 这里可以实现获取选中节点的逻辑
      // item 是当前节点
      // isSelect 是一个布尔值，表示当前节点是否被选中
      // indeterminate 是一个布尔值，表示当前节点的子树是否完全被选中
      console.log("item", item);
      if (!item.property) {
        return;
      }

      let index = this.form.condition.findIndex((_item) => {
        return item.object == _item.object && item.property == _item.property;
      });
      console.log("index", index);

      if (index >= 0 && !isSelect) {
        this.form.condition.splice(index, 1);
        return;
      }
      if (isSelect) {
        let fullPath = this.getPath(item);
        // 将路径中的标签拼接成一个字符串，如 "通用 风速"
        const html = fullPath.join(" ");
        console.log("选中的节点路径:", html);
        this.form.condition.push({
          object: item.object,
          property: item.property,
          operator: null,
          value: null,
        });
      }
      console.log(" this.form.condition", this.form.condition);
    },
    async onAdd() {
      try {
        await this.$refs.form.validate();
        this.form.condition.map((item) => {
          let isv = !(!item.operator || parseFloat(item.value) == NaN || item.value === '' || item.value == null);
          if (!isv) {
            this.$message(`请完整填写${deviceObjectParseByType(`${item.object}.${item.property}`, this.getDeviceType())}阈值信息`);
            throw isv;
          }
        });
        let index = this.record.findIndex((_item) => {
          return _item.label == this.form.label;
        });
        if (index >= 0) {
          await this.$confirm(`已存在${this.form.label}的阈值项，是否替换?`);
        }
        let loading = this.$loading();
        let res = await this.addSubmit();
        loading.close();
        if (res.command != 'Succeed') {
          this.$message(res.command == 'timeout' ? '请求超时，请稍后重试。' : '添加失败');
          return;
        }
        if (index >= 0) {
          this.$message('替换成功');
          this.record.splice(index, 1, this.form);
        } else {
          this.$message('添加成功');
          this.record.unshift(this.form);
        }
        this.$emit('change', this.record);
        this.form = {
          label: null,
          level: null,
          condition: []
        };
        this.$refs.tree.setCheckedNodes([]);
      } catch (error) {
        return;
      }
    },
    async addSubmit() {
      let supplyData = {
        command: 'WriteThreshold',
        threshold: [this.form]
      };
    
    
      this.mqttSubmit = demandPublish(this.title, supplyData);
      return this.mqttSubmit.publish();
      
    }
  },
};
</script>

<style lang="less" scoped>
.treeBox {
  height: 100%;
  width: 15%;
  background-color: #081624;
  color: #fff;
}
.warnbox{
  overflow: auto;
  width: 40%;
}
/deep/.treeBox .el-tree-node__content:hover {
  background-color: #071724; /* 设置鼠标移入时的背景色为透明 */
}
/deep/.treeBox .el-tree-node.is-current > .el-tree-node__content {
  background-color: #071724; /* 设置选中节点的背景色为浅蓝色 */
}
.flex {
  display: flex;
  justify-content: space-between;
  height: 100%;
  color: #fff;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: #14324b;
  color: #fff;
}
/deep/ .el-table th:hover,
/deep/ .el-table tr:hover,
/deep/ .el-table td:hover {
  background-color: transparent;
}
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #1c1c1b4a !important;
}
/deep/.el-table .el-table__body td {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table thead {
  color: #00aeff;
  font-weight: 500;
  background-color: #051b30;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.calibration {
  background-color: #051b30;
  padding: 10px;
  color: #fff;
}
.btns {
  width: 100%;
  background-color: #0d2335;
  text-align: center;
  color: #5da444;
  height: 40px;
  line-height: 40px;
}
.btns:hover {
  cursor: pointer;
}
.determine {
  color: #5da444;
  margin-right: 10px;
}
.determine:hover {
  cursor: pointer;
}
.del:hover {
  cursor: pointer;
}
/deep/ .el-select {
  position: relative;
  .el-select-dropdown.el-popper {
    position: absolute !important;
    width: 100%;
  }
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      height: auto;
      line-height: initial;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;
      overflow: hidden;
      .el-select-dropdown__item_label {
        color: #000000;
      }
      .el-select-dropdown__item_describe {
        color: #454545;
        font-size: 0.8em;
        text-decoration: none;
        font-weight: 400;
      }
      &.selected {
        .el-select-dropdown__item_label {
          color: #409eff;
        }
      }
      &.hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
