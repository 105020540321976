<!--  -->
<template>
  <div style="height: 100%; overflow-y: auto">
    <div style="height: 100%; width: 100%;" >
      <div class="dataBox">
        <div class="data">
          <div class="boxs">最大重量:{{ data.maxWight ? data.maxWight : "暂无" }} (吨)</div>
          <div class="boxs">大车高度:{{ data.gantryHeight ? data.gantryHeight : 0 }} (米)</div>
          <div class="boxs">小车高度:{{ data.trolleyHeight ? data.trolleyHeight : 0 }} (米)</div>
          <!-- <div class="boxs">塔机经度:{{ data.longitude ? data.longitude : 0 }} (度)</div> -->
        </div>
        <!-- <el-divider></el-divider> -->
        
      </div>
      <!-- <div class="dataBox">
        <div class="data">
          <div class="boxs">
            首节高度:{{ data.fristsection ? data.fristsection : 0 }} (米)
          </div>
          <div class="boxs">起重臂宽度:{{ data.armwidth ? data.armwidth : 0 }} (米)</div>
          <div class="boxs">起重臂长度:{{ data.armlength ? data.armlength : 0 }} (米)</div>
          <div class="boxs">平衡臂长度:{{ data.reararm ? data.reararm : 0 }} (米)</div>
       
         
        </div>
        
      </div> -->
      <!-- <div class="dataBox">
        <div class="data">
          <div class="boxs">参考零点:{{ data.heightzero ? data.heightzero : "暂无" }}</div>
          <div class="boxs">塔高:{{ data.craneheight ? data.craneheight : 0 }} (米)</div>
          <div class="boxs">节高:{{ data.sectionheight ? data.sectionheight : 0 }} (米)</div>
          <div class="boxs">节数:{{ data.sectionnum ? data.sectionnum : 0 }} (节)</div>
        </div>
        <div class="">
        
        </div>
      </div> -->
      <!-- <div class="dataBox">
        <div class="data">
          <div class="boxs">最大吊重:{{ data.maxload ? data.maxload : 0 }} (吨)</div>
          <div class="boxs">
            顶升爬架的高度:{{ data.climbheight ? data.climbheight : 0 }} (米)
          </div>
          <div class="boxs">塔吊轨道长度:{{ data.rail ? data.rail : 0 }} (米)</div>
          <div class="boxs">最大力矩:{{ data.maxmoment ? data.maxmoment : 0 }} (吨)</div>
        </div>
        <div class="">
        
        </div>
      </div> -->
      <!-- <div class="dataBox">
        <div class="data">
          <div>臂根较接偏置距离(动臂) :2 (米)</div>
          
        </div>
        <div class="">
          <img src="../../assets/images/screen/line.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["workData"],
  data() {
    return {
      data: {},
    };
  },
  mounted() {},
  watch: {
    workData(val) {
      this.data = val;
      if (this.data.type == "flat-top") {
        this.data.type = "平臂式";
      } else if (this.data.type == "luffingjib") {
        this.data.type = "动臂式";
      }
      if (this.data.heightzero == "top") {
        this.data.heightzero = "顶点";
      } else if (this.data.heightzero == "bottom") {
        this.data.heightzero = "底部";
      }
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.data {
 display: flex;
  color: #fff;
  // text-align: left; 
  width: 100%;
  // justify-content: space-between;
  // border: 1px solid red;
}
.dataBox {
  // border: 1px solid red;
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-around;
//  line-height:45px;


}
.boxs{
  
  // background-color: aquamarine;
 
  // padding: 5px 10px;
  width: 100%; 
}

</style>
