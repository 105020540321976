<template>
  <el-dialog
      class="custom-dialog bg"
      :title="title"
      :visible.sync="visible"
      :width="width || '60%'"
      append-to-body
      :before-close="handleClose">
    <div slot="title" class="dialog-title">{{title}} &nbsp;</div>
    <slot></slot>
    <!-- <div slot="footer" class="dialog-footer">
    
      <el-button class="dialog-btn sure"  size="medium"   @click="submit" v-if="!showEdit"   type="success">保 存</el-button>
      <el-button class="dialog-btn del" size="medium" @click="handleClose"   type="success">取 消</el-button>
    </div> -->
  </el-dialog>
</template>

<script>
export default {
  props: ['visible', 'width','title','showEdit'],
  name: "CustomDialog",
  methods:{
    handleClose(){
      this.$emit('close')
    },
    submit(){
      this.$emit('submit')
    }
  }
}
</script>

<style scoped lang="less">
.dialog-title{
  font-weight: bolder;
}
.dialog-btn{
  padding: 10px 35px;
}
.sure{
  background-color: #008f4d;
  color: #ffffff;
}
.del:hover{
  color: #008f4d;
  background-color: #ffffff;
  border:1px solid #008f4d;
}
.del{
  color: #008f4d;
  background-color: #ffffff;
  border:1px solid #008f4d;
}
// .bg{
//   background-color: #072640;
// }
/deep/.el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #072640;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    box-sizing: border-box;
    width: 50%;
}
</style>