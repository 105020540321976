
<template>
 <div class="page">
    <div class="title">设备类型：
        <span v-if="equmentObj.deviceType == 0">塔吊</span>
          <span v-if="equmentObj.deviceType == 1">升降机</span>
          <span v-if="equmentObj.deviceType == 2">龙门吊</span>
          <span v-if="equmentObj.deviceType == 3">架桥机</span>
          <span v-if="equmentObj.deviceType == 4">爬模</span>
          <span v-if="equmentObj.deviceType == 5">挂篮</span>
          <span v-if="equmentObj.deviceType == 8">扬尘</span>
          <span v-if="equmentObj.deviceType == 9">喷淋</span>
    </div>
    <div class="title">所属项目：{{ equmentObj.projName }}</div>
    <div class="title">生产厂家：{{ equmentObj.equipFactory }}</div>
    <div class="title">出厂时间：{{ equmentObj.equipDate }}</div>
    <!-- <div class="title">许可证书：{{ equmentObj.projName }}</div> -->
    <div class="title">到期时间：{{ equmentObj.endTime }}</div>
    <div class="title">负责人：{{ equmentObj.projHead }}</div>
    <div class="title">联系电话：{{ equmentObj.projTel }}</div>
 </div>
</template>

<script>
export default {
    props:['equmentObj'],
 components:{

 },

 data () {
  return {

  }
 },
 mounted(){

 },
 methods:{

 },
}

</script>

<style lang='less' scoped>
.page{
    color: #fff;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // padding: 2px;
}
.title{
    width: 50%;
}
</style>
