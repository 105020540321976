<!--  -->
<template>
  <drawerDialog
    :visible.sync="visible"
    @handleClose="handleClose"
    title="力矩设置"
    style="width: 100%"
  >
    <el-table :data="gridData">
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column label="幅度" align="center" width="200">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.travel"
            placeholder="请输入内容"
            @input="convertToNumber(scope.row, 'travel')"
            type="number"
            size="small "
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="重量" align="center" width="200">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.weight"
            type="number"
            @input="convertToNumber(scope.row, 'weight')"
            placeholder="请输入内容"
            size="small "
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="del" @click="del(scope.$index)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="btns" @click="add"><i class="el-icon-plus"></i>新增</div>
    <div class="demo-drawer__footer">
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </drawerDialog>
</template>

<script>
import drawerDialog from "../../../components/common/drawerDialog";
import { createConnect, endConnect } from "../../../api/mqtt";
import mqtt from "mqtt";
import { demandPublish } from "../../../utils/index";
import screen from "../../../api/modules/screen";
export default {
  props: ["visible", "title"],
  components: {
    drawerDialog,
  },
  data() {
    return {
      form: {},
      formLabelWidth: "80px",
      timer: null,
      loading: false,
      rules: {
        type: [
          { required: true, message: "请选择塔吊类型", trigger: "change" },
        ],

        armlen: [
          { required: true, message: "请输入主臂长度", trigger: "blur" },
        ],
        fristsection: [
          { required: true, message: "请输入首节高度", trigger: "blur" },
        ],

        sectionhigh: [
          { required: true, message: "请输入节高", trigger: "blur" },
        ],
        sectionnum: [
          { required: true, message: "请输入节数", trigger: "blur" },
        ],
        rate: [{ required: true, message: "请输入倍率", trigger: "blur" }],
        armwidth: [
          { required: true, message: "请输入主臂宽度", trigger: "blur" },
        ],
        safelen: [
          { required: true, message: "请输入安全距离", trigger: "blur" },
        ],
      },
      loading: false,
      versionccs: "",
      gridData: [],
      travel: 0,
      weight: 0,
      table: false,
      times: "",
      momentData: "",
    };
  },
  mounted() {
    this.onShowMoment(this.title);
  },
  // watch: {
  //   title(val){
  //     this.onShowMoment(val)
  //   }
  // },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    convertToNumber(row, field) {
    row[field] = parseFloat(row[field]);
  },
    //订阅力矩
    onShowMoment(val) {
      let params = {
        deviceImei: val,
        termTopic: "Moment",
      };
      screen.getTerm(params).then((res) => {
        console.log("res力矩", res);

        let data = JSON.parse(res.data[0].termData);
        console.log("力矩设置", data);
        this.momentData = data.moment[0];
        this.gridData = data.moment[0].point;
        this.times = data.timestamp;
      });
    },
    async onSubmit() {
      this.momentData.point = this.gridData;
      let moment = [this.momentData];
      let momentSetDataMqtt = {
        command: "CfgMoment",
        moment: moment,
      };
      console.log('momentSetDataMqtt',momentSetDataMqtt);
      
      let loading = this.$loading();
      this.mqttSubmit = demandPublish(this.title, momentSetDataMqtt);
      let res = await this.mqttSubmit.publish();
      loading.close();
      if (res.command != "Succeed") {
        this.$message("保存失败");
        return;
      }
      this.$message("保存成功");
      // this.onShowMoment()
      this.onShowMoment(this.title);
    },

    add() {
      this.gridData.push({
        travel: "",
        weight: "",
      });
    },
    del(index) {
      this.gridData.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: #0d2335;
  color: #fff;
}
/deep/ .el-table th:hover,
/deep/ .el-table tr:hover,
/deep/ .el-table td:hover {
  background-color: transparent;
}
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #1c1c1b4a !important;
}
/deep/.el-table .el-table__body td {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table thead {
  color: #00aeff;
  font-weight: 500;
  background-color: #051b30;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.btns {
  width: 100%;
  background-color: #0d2335;
  text-align: center;
  color: #5da444;
  height: 40px;
  line-height: 40px;
}
.btns:hover {
  cursor: pointer;
}
.del:hover {
  cursor: pointer;
}
</style>
