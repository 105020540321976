<!--  -->
<template>
  <drawerDialog
    :visible.sync="visible"
    @handleClose="handleClose"
    title="标定"
    size="42%"
  >
    <div class="flex">
      <el-tree
        class="treeBox"
        :data="data"
        :highlight-current="true"
        :props="defaultProps"
        :default-expand-all="true"
        @node-click="handleNodeClick"
      ></el-tree>
      <div style="width: 60%; overflow-y: auto">
        <div class="c-box-header" v-for="(item,index) in calibrationList" :key="index">
      <div>{{ getObject(item.object) }}</div>
      <div>{{ getProperty( item.property) }}</div>
      <el-table :data="item.point" style="width: 100%">
      <el-table-column prop="AD" label="模拟量">
       
      </el-table-column>
      <el-table-column prop="value" label="值">
        <template slot-scope="scope">
          <el-input v-if="scope.row.isNew" size="mini" type="number" v-model.number="scope.row.value"></el-input>
          <span v-else>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            style="color: #67c23a"
            v-if="scope.row.isNew"
            size="mini"
            type="text"
            icon="el-icon-check"
            @click="onPoint(scope.row, scope.$index,calibrationList[index])"
            >标定</el-button
          >
          <el-button style="color: #f56c6c" size="mini" type="text" icon="el-icon-close"  @click="onDel(calibrationList[index], scope.$index,scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div
            class="btns"
            @click="add(item.property,item.object)"
            v-if="item.property=='height@encoder'||item.property=='weight@analog'||item.property=='travel@encoder' ? true : false"
          >
            <i class="el-icon-plus"></i>新增
          </div>
    </div>
 
      </div>
    </div>
  </drawerDialog>
</template>

<script>
import drawerDialog from "../../../components/common/drawerDialog";
import { createConnect, endConnect } from "../../../api/mqtt";
import mqtt from "mqtt";
import { demandPublish } from "../../../utils/index";
import screen from "../../../api/modules/screen";
export default {
  props: ["visible", "title", "realTimeData"],
  components: {
    drawerDialog,
  },
  data() {
    return {
      data: [
        {
          label: "笼",
          value: [],
          children: [
            {
              label: "编码器高度",
              value:[ "height@encoder"],
           
            },
            {
              label: "模拟信号重量",
              value:['weight@analog'],
              
            },
          ],
        },
     
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      gridData: [],
      calibrationList: [],
    };
  },
  mounted() {
    this.getCalibrationData([],this.title);
  },
  watch: {
    realTimeData(val) {},
    visible(val) {
      this.getCalibrationData([],this.title);
    },
  },
  methods: {
    async onPoint(item, index,info) {
      console.log('item',item);
     console.log('info',info);
     
   
      if (!item.AD || item.AD == 'N/A') {
        this.$message('未获取到模拟量');
        return;
      }
      if (item.value === '' || item.value == null || item.value == undefined) {
        this.$message('请输入标定值');
        return;
      }
      let isEd = info.point.find((_item) => {
        return !_item.isNew && (item.AD == _item.AD || item.value == _item.value);
      });
      if (isEd) {
        this.$message('模拟量或者标定值已存在');
        return;
      }
      let res = await this.pointSubmit(item,info);
      if (res.command != 'Succeed') {
        this.$message(res.command == 'timeout' ? '请求超时，请稍后重试。' : '标定失败');
        item.isNew = true;
        return;
      }
      this.$message('标定成功');
      item.isNew = undefined;
    },
    async pointSubmit(item, info) {
      let supplyData = {
        command: 'WriteCalibration',
        calibration: [
          {
            object: info.object,
            property: info.property,
            point: [
              {
                AD: item.AD,
                value: item.value
              }
            ]
          }
        ]
      };
     
     console.log('supplyData',supplyData);
    
      let loading = this.$loading();
      this.mqttSubmit = demandPublish(this.title, supplyData);
      let res = await this.mqttSubmit.publish();
      loading.close();
      return res;
    },
    getObject(objectValue){
    console.log('objectValue',objectValue);
    
      
      if (objectValue === "hoist") {
        return "笼";
      } else if (objectValue === "gantry_front.hoist") {
        return "前天车起升";
      } else if (objectValue === "gantry_rear") {
        return "后天车";
      } else if (objectValue === "gantry_rear.hoist") {
        return "后天车起升";
      }
    },
    getProperty(propertyValue){
    
      const propertyDescriptions = {
        'vertical': "垂直 vertical",
        'height': "高度 height",
        'weight': "重量 weight",
        'horizontal': "水平 horizontal",
        'weight@analog': "模拟信号重量 weight@analog",
        'height@encoder': "编码器高度 height@encoder",
        'travel@encoder':'编码器行程 travel@encoder',
       

        // 可以根据需要添加更多的映射关系
      };
      return propertyDescriptions[propertyValue] || propertyValue;
    },
    handleClose() {
      this.$emit("handleClose");
    },
    
    getCalibrationData(data) {
      let params = {
        parts:data.value,
        deviceImei:this.title
      }
      screen.realDataLists(params).then((res) => {
        console.log("resxxxxxx", res);

        // let dataJson = JSON.parse(res.data[0].termData);
        let data =res.data[0].calibration;
        this.calibrationList = data;


        this.gridData = this.calibrationList;
      });
    },
    add(val,object) {
      console.log("val", val);
      console.log("object", object);
      console.log("this.realTimeData", this.realTimeData);
      
      if (object=='hoist') {
        let AD = "";
        if (val == "weight@analog") {
          AD = this.realTimeData.weightAnalog;
        } else if (val == "height@encoder") {
          AD = this.realTimeData.heightAnalog;
        } 
        this.calibrationList.map((item) => {
          console.log('item',item);
          
          if (item.property == val ) {
            item.point.push({
              AD: AD,
              value: "",
              isNew: true,
            });
          }
        });
      }
    },
// 删除
async onDel(calibrationObject, pointIndex,items) {
  
  // calibrationObject 是 calibrationList 中的对象
  // pointIndex 是该对象中 point 数组的索引
  if (calibrationObject.point[pointIndex].isNew) {
    // 如果是新添加的项，则直接从数组中删除
    calibrationObject.point.splice(pointIndex, 1);
  } else {
    // 如果是已存在的项，可能需要执行异步删除操作
    // 这里省略了异步操作的代码，因为您的代码中没有提供这部分逻辑
    // ...
    let res = await this.delSubmit(calibrationObject, pointIndex,items);
      if (res.command != 'Succeed') {
        this.$message(res.command == 'timeout' ? '请求超时，请稍后重试。' : '删除失败');
        return false;
      }
      this.$message('删除成功');
      calibrationObject.point.splice(pointIndex, 1);
     
      return;
  }
},
 async delSubmit(item, index,items) {
  console.log('item',item);
  
  
      let supplyData = {};
      if (item.point.length > 1) {
        supplyData.command = 'DeleteCalibration';
        supplyData.calibration = [
          {
            ...item,
            point: [items]
          }
        ];
      } else {
        supplyData.command = 'ClearCalibration';
        supplyData.calibration = [
          {
            object: item.object,
            property: item.property
          }
        ];
      }
  
      
      let loading = this.$loading();
      this.mqttSubmit = demandPublish(this.title, supplyData);
      let res = await this.mqttSubmit.publish();
      loading.close();
    
      return res;
    },
//     handleNodeClick(data) {
//   console.log('data', data.isFont);

//   let params = {
//     parts: data.value,
//     deviceImei: this.title
//   };

//   screen.realDataLists(params).then(res => {
//     console.log('res', res);

//     // 使用map来遍历res.data，并根据条件返回对象
//     // 使用filter来移除null值
//     this.calibrationList = res.data
//       .map(item => {
//         if ((data.isFont === 'front' && item.object === 'gantry_front.hoist') ||
//             (data.isFont === 'rear' && item.object === 'gantry_rear.hoist')) {
//           return item; // 只返回符合条件的对象
//         }
//         return null; // 不符合条件时返回null
//       })
//       .filter(item => item !== null); // 过滤掉null值

//     console.log('this.calibrationList', this.calibrationList);
//   });
// }
    handleNodeClick(data) {
   console.log('data',data);

      let params = {
        parts:data.value,
        deviceImei:this.title
      }
   
      
      screen.realDataLists(params).then(res=>{
       
        
        // let datas =res.data;
        console.log('res',res);
        
       res.data.forEach(item=>{
      
      console.log('item',item);
      
        if(item.property=='height@encoder' ){
       
          this.calibrationList=[item]
         
          
          return
        }
         else if(item.object=='weight@analog'){
          this.calibrationList=[item]
          return
        }
        else{
          this.calibrationList = item.calibration ;
          return
        }
       })
        // 


        // this.gridData = this.calibrationList;
        
      })

}
  },
};
</script>

<style lang="less" scoped>
.treeBox {
  height: 100%;
  width: 35%;
  background-color: #14324b;
  color: #fff;
}

/deep/.treeBox .el-tree-node__content:hover {
  background-color: #071724; /* 设置鼠标移入时的背景色为透明 */
}
/deep/.treeBox .el-tree-node.is-current > .el-tree-node__content {
  background-color: #071724; /* 设置选中节点的背景色为浅蓝色 */
}
.flex {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: #14324b;
  color: #fff;
}
/deep/ .el-table th:hover,
/deep/ .el-table tr:hover,
/deep/ .el-table td:hover {
  background-color: transparent;
}
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #1c1c1b4a !important;
}
/deep/.el-table .el-table__body td {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table thead {
  color: #00aeff;
  font-weight: 500;
  background-color: #051b30;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
.calibration {
  background-color: #051b30;
  padding: 10px;
  color: #fff;
}
.btns {
  width: 100%;
  background-color: #0d2335;
  text-align: center;
  color: #5da444;
  height: 40px;
  line-height: 40px;
}
.btns:hover {
  cursor: pointer;
}
.determine {
  color: #5da444;
  margin-right: 10px;
}
.determine:hover {
  cursor: pointer;
}
.del:hover {
  cursor: pointer;
}
.c-box-header{
  color: #fff;
}
</style>
