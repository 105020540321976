<!--  -->
<template>
  <div style="height: 100%">
    <div style="height: 100%;overflow: auto;" >
      <el-table
        :data="tableData"
        style="width: 100%; color: #ffffff;"
        :height="heights"
        
       
      >
        <el-table-column prop="alarmTime" label="时间" show-overflow-tooltip align="center"> </el-table-column>
        <!-- <el-table-column prop="alarmValue" label="告警值"> </el-table-column>
        <el-table-column prop="alarmStatus" label="告警状态">
          <template slot-scope="scope">
            <div v-if="scope.row.alarmStatus == 0">{{ "已消警" }}</div>
            <div v-if="scope.row.alarmStatus == 1">{{ "未消警" }}</div>
         
          </template>
        </el-table-column> -->
        <el-table-column prop="alarmType" label="类别" align="center"> 
          <template slot-scope="scope">
            <div v-if="scope.row.alarmType == 0">{{ "告警" }}</div>
            <div v-if="scope.row.alarmType == 1">{{ "违章" }}</div>
           
          </template>
        </el-table-column>
        <!-- <el-table-column prop="alarmLevel" label="级别" > </el-table-column>
        <el-table-column prop="alarmDetail" label="内容" show-overflow-tooltip>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import screen from "../../../api/modules/screen";
export default {
  props: ["title",'tableHeight'],
  components: {},
  data() {
    return {
      tableData: [],
      heights:''
    };
  },
  watch: {
    title(val) {
      console.log("val", val);
      this.getAlarmTodayLists(val);
    },
    tableHeight(val){
     
      this.heights=val
    }
  },
  mounted() {
    // this.getAlarmTodayLists();
    //     setInterval(() => {
    //   // （也可以使用`this.$ref`获得表格元素让母后`.classList.remove("el-table--enable-row-hover")`）
    //       document.getElementById("playList").classList.remove("el-table--enable-row-hover")
    //   })
  },
  methods: {
    getAlarmTodayLists(deviceImei) {
     
      screen.getProjectAlarmList({ deviceImei:deviceImei }).then((res) => {
        console.log('res',res);
       this.tableData=res.data
      });
    },
  },
};
</script>

<style lang="less" scoped>
/*最外层透明*/
/*最外层透明*/
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}
/deep/ .el-table th:hover,
/deep/ .el-table tr:hover,
/deep/ .el-table td:hover {
  background-color: transparent;
}
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #1c1c1b4a !important;
}
/deep/.el-table .el-table__body td {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table thead {
  color: #00aeff;
  font-weight: 500;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/* 修改滚动条的样式 */
/deep/.el-table__body-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent !important; /* 滚动条thumb颜色 */
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  
  background-color: transparent !important; /* 滚动条thumb颜色 */
  height: 1px;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: transparent !important; /* 滚动条track颜色 */
  height: 6px;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: transparent !important; /* 滚动条thumb悬停时颜色 */
  height: 1px;
}
/* 修改水平滚动条的样式 */
/deep/.el-table__body-wrapper {
  overflow-x: auto;
  height: 1px;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar {
  height: 3px;
  
}



</style>
