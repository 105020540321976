<!--  -->
<template>
    <drawerDialog :visible.sync="visible" @handleClose="handleClose" title="工况"  >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="ruleForm">
   
          <el-form-item
            label="最大重量"
            :label-width="formLabelWidth"
            prop="maxweight"
          >
            <el-input
              placeholder="请输入最大重量"
              v-model="form.maxweight"
              type="number"
            >
              <template slot="append">吨</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="最大高度"
            :label-width="formLabelWidth"
            prop="maxheight"
          >
            <el-input
              placeholder="请输入最大高度"
              v-model="form.maxheight"
              type="number"
            >
              <template slot="append">米</template>
            </el-input>
          </el-form-item>
        
        </el-form>
        <div class="demo-drawer__footer">
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </div>
      </div>
    </drawerDialog>
  </template>
  
  <script>
  import drawerDialog from "../../../components/common/drawerDialog";
  import { createConnect, endConnect } from "../../../api/mqtt";
  import mqtt from "mqtt";
  import { demandPublish } from '../../../utils/index';
  export default {
    props: ["visible", "workData", "versioncc","title"],
    components: {
      drawerDialog,
    },
    data() {
      return {
        form: {},
        formLabelWidth: "80px",
        timer: null,
        loading: false,
        rules: {
          type: [
            { required: true, message: "请选择塔吊类型", trigger: "change" },
          ],
  
          maxweight: [
            { required: true, message: "请输入最大重量", trigger: "blur" },
          ],
          maxheight: [
            { required: true, message: "请输入最大高度", trigger: "blur" },
          ],
  
          sectionhigh: [
            { required: true, message: "请输入节高", trigger: "blur" },
          ],
          sectionnum: [
            { required: true, message: "请输入节数", trigger: "blur" },
          ],
          rate: [{ required: true, message: "请输入倍率", trigger: "blur" }],
          armwidth: [
            { required: true, message: "请输入主臂宽度", trigger: "blur" },
          ],
          safelen: [
            { required: true, message: "请输入安全距离", trigger: "blur" },
          ],
        },
        loading:false,
        versionccs: "",
        options: [
          { label: "平头", value: "crop" },
          { label: "尖头", value: "cusp" },
        ],
      };
    },
    mounted() {},
    watch: {
      workData(val) {
        console.log("val", val);
        this.form = val;
      },
      versioncc(val) {
        this.versionccs = val;
      },
      // visible(val){
      //   this.form = val;
      // }
    },
    methods: {
      handleClose() {
        this.$emit("handleClose");
      },
        onSubmit() {
        this.$refs["ruleForm"].validate(async(valid) => {
          if (valid) {
            let workset = [];
            workset.push(this.form);
            let workSetDataMqtt = {
                command: "CfgWorkset",
                workset: workset,
               
              };
              let loading = this.$loading();
              this.mqttSubmit = demandPublish(this.title, workSetDataMqtt);
              let res = await this.mqttSubmit.publish();
              loading.close();
              if (res.command != 'Succeed') {
            this.$message('保存失败');
            return;
          }
          this.$message('保存成功');
        //   this.$emit("rest");
          // this.onChange();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      cancelForm() {
        this.loading = false;
        this.dialog = false;
        clearTimeout(this.timer);
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  /deep/.el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  </style>
  