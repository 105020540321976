<!--  -->
<template>
  <drawerDialog
    :visible.sync="visible"
    @handleClose="handleClose"
    title="实时记录"
    size="60%"
  >
    <div class="block" style="width: 40%">
      <el-date-picker
        v-model="value1"
        @change="changeDate"
        type="datetimerange"
        format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
       <el-button type="primary" @click="onSubmit" size="small" style="margin-left: 10px;">查询</el-button>
       <el-button type="primary" @click="onRest" size="small">重置</el-button>
    </div>
    <div class="realTimeBox">
      <div >
        <el-table
          :data="gridData"
          style="width: 100%; height: 100%;"
       
       
          @cell-mouse-enter="handlCellMouse"
        >
          <el-table-column
            label="时间"
            align="center"
            show-overflow-tooltip
            prop="deviceTime"
          >
          </el-table-column>
          <!-- <el-table-column label="制动次数(次)" align="center" >
        </el-table-column> -->
          <el-table-column label="高度(米)" align="center" prop="height">
          </el-table-column>
          <el-table-column label="溜勾(米)" align="center" prop="slide">
          </el-table-column>
          <el-table-column label="溜勾时间(秒)" align="center" prop="slidetime"  show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column label="速度(米/分)" align="center" >
        </el-table-column> -->
          <el-table-column label="重量(吨)" align="center" prop="weight">
          </el-table-column>
          <!-- <el-table-column label="力矩(吨*米)" align="center" >
        </el-table-column> -->
          <el-table-column label="速度(米/分)" align="center" prop="speed">
          </el-table-column>
         
        </el-table>
        <el-pagination
          class="pag-right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <bridgeHistory
        :workData="workData"
        :realTimeData="realTimeData"
      ></bridgeHistory>
    </div>
  </drawerDialog>
</template>

<script>
import drawerDialog from "../../../components/common/drawerDialog";
import { turnTime } from "../../../utils/tool";
import screen from "../../../api/modules/screen";
import bridgeHistory from "./bridgeHistory.vue";
export default {
  props: ["visible", "title","workData",'deviceType'],
  components: {
    drawerDialog,
    bridgeHistory,
  },
  data() {
    return {
      form: {},
      formLabelWidth: "80px",
      timer: null,
      loading: false,

      loading: false,
      versionccs: "",
      gridData: [],
      travel: 0,
      weight: 0,
      table: false,
      times: "",
      momentData: "",
      Page: {
        currPage: 1,
        pageSize: 10,
      },
      total: 0,
     
      realTimeData: {},
      value1: [new Date(new Date().setHours(0, 0, 0, 0)), new Date()],
      startDate: "",
      endDate: "",
    };
  },
  mounted() {
    this.getHistoryData(this.title);
   
    // this.realTimeData={
    //   height:60,
    //   load:30,
    //   luffing:20,
    //   rotation:100
    // }
  },
  watch: {
    title(val) {
      this.getHistoryData(val)
    },
    visible(val){
      this.getHistoryData(this.title);
    }
  },
  methods: {
    onSubmit(){
      this.getHistoryData(this.title);
    },
    onRest(){
      this.startDate = '';
      this.endDate = '';
      this.value1=''
      this.getHistoryData(this.title);
    },
    handleSizeChange(pageSize) {
      this.Page.pageSize = pageSize;
      this.Page.currPage = 1;
      this.getHistoryData(this.title);
    },
    handleCurrentChange(pageIndex) {
      this.Page.currPage = pageIndex;
      this.getHistoryData(this.title);
    },
    changeDate(value) {
      this.startDate = turnTime(value[0]);
      this.endDate = turnTime(value[1]);
    },

    getHistoryData(title) {
      let param = {
        deviceImei: title,
        endTime:this.endDate,
        startTime: this.startDate,
        currPage: this.Page.currPage,
        pageSize: this.Page.pageSize,
        type:this.deviceType
      };
      screen.selectEsData(param).then((res) => {
        console.log(res, "历史实时x");
        this.gridData = res.data.records;
        this.total = res.data.total;
        this.realTimeData = {
          height: this.gridData[0].height,
          load: this.gridData[0].weight,
          speed: this.gridData[0].speed,
          rotation: this.gridData[0].rotation,
        };
        // this.workData = {
        //   craneheight: 80,
        //   armlen: this.gridData[0].armlen ? this.gridData[0].armlen : 45,
        // };
      });
    },

    handleClose() {
      this.$emit("handleClose");
    },
    handlCellMouse(row) {
    
      this.realTimeData = {
        height: row.height,
        load: row.weight,
        speed: row.speed,
        rotation: row.rotation,
      };
      // this.workData = {
      //   craneheight: 80,
      //   armlen: row.armlen ? row.armlen : 45,
      // };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: #0d2335;
  color: #fff;
}
/deep/ .el-table th:hover,
/deep/ .el-table tr:hover,
/deep/ .el-table td:hover {
  background-color: #2b2e44;
}
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #1c1c1b4a !important;
}
/deep/.el-table .el-table__body td {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table thead {
  color: #00aeff;
  font-weight: 500;
  background-color: #051b30;
}

.btns {
  width: 100%;
  background-color: #0d2335;
  text-align: center;
  color: #5da444;
  height: 40px;
  line-height: 40px;
}
.btns:hover {
  cursor: pointer;
}
.del:hover {
  cursor: pointer;
}
div::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/deep/div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #cccccc;
  // background: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}

/deep/div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: transparent;
}
.pag-right {
  text-align: right;
  margin-top: 20px;
}
.head-btn {
  padding: 10px 20px;
  border: 1px solid #f0f0f0;
  border-bottom: none;
  //border-top-left-radius: 10px;
  //border-top-right-radius: 10px;
  .table-title {
    font-weight: bolder;
    position: relative;
    top: 6px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.right {
  display: flex;
  justify-content: right;
  color: #fff;

  .el-button {
    margin-left: 15px;
  }
}
/deep/.el-pagination__total {
  margin-right: 10px;
  font-weight: 400;
  color: #fff;
}
/deep/.el-pagination__jump {
  margin-left: 24px;
  font-weight: 400;
  color: #fff;
}
.realTimeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.block{
  display: flex;
  align-items: center;
}
</style>
