<template>
    <component :is="vidoTYpeObj[deviceType]"  :visible.sync="visible" @handleClose="handleClose"  @rest="rest" :title="title" :realTimeData="realTimeData"> </component>
  </template>
  
  <script>
import towerCalibrationData from '../towerCalibrationData.vue'
import liftCalibrationData from '../liftCalibrationData.vue'
import bridgeCalibrationData from '../bridgeCalibrationData.vue'
import doorCalibrationData from '../doorCalibrationData.vue'

  export default {
    name: "index",
    props: ["visible","deviceType","title","realTimeData"],
    components: {
        towerCalibrationData,
        liftCalibrationData,
        bridgeCalibrationData,
        doorCalibrationData
    
    },
    watch: {
      data(val) {
        console.log(val);
      },
    },
    data() {
      return {
        MonitorSwitchpageOR: "",
        vidoTYpeObj: {
          0: "towerCalibrationData",
          1: "liftCalibrationData",
          2:'doorCalibrationData',
          3: "bridgeCalibrationData",

        },
      };
    },
    methods:{
        handleClose() {
      this.$emit("handleClose");
    },
    rest(){
        this.$emit("rest");
    }
    }
  };
  </script>
  
  <style scoped></style>
  