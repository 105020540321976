
import mqtt from '../api/mqttss';

// 对象解析
export function deviceObjectParse(object, sourceObj = strConfig) {
    let objectStr = (function get(objectArr) {
        return objectArr.map((item) => {
            if (item.includes(",")) {
                let _strArr = get(item.split(','))
                return _strArr.join("")
            }
            if (item.includes("@")) {
                let _strArr = get(item.split('@').reverse())
                return _strArr.join("")
            }
            if (item.includes(".")) {
                let _strArr = get(item.split('.'))
                return _strArr.join("")
            }
            if (item.includes("_")) {
                let _strArr = get(item.split('_')).reverse()
                return _strArr.join("")
            }
            if (item.includes("#")) {
                let _strArr = get(item.split('#'))
                return _strArr.join("")
            }
            return sourceObj[item] || item
        });
    })([object])
    return objectStr.join("")
}

// 对象解析BY类型
export function deviceObjectParseByType(object, type) {
    return deviceObjectParse(object, StrConfigByType[type] || undefined)
}

// 默认字段配置
function defPropertyConfigFun(key, object, fieldConfig = FieldConfig) {
    return !(object.includes('workcycle') || object.includes('CMD')) && fieldConfig[key]
}

// 默认字段配置By类型
function defPropertyConfigFunByType(key, object, type) {
    return propertyConfigFun(key, object, FieldConfigByType[type] || undefined)
}


export function hasTreeObject(tree, object) {
    return (function has(_tree) {
        for (let i in _tree) {
            if (_tree[i].object == object) {
                return true
            }
            if (_tree[i].children.length) {
                if (has(_tree[i].children)) {
                    return true
                }
            }
        }
    })(tree);
}
// 获取对象树
export function getObjectTree(data, propertyConfiglFun = defPropertyConfigFun, type = undefined) {
    return (function get(data, p = { object: '' }) {
        let tree = [];
        for (let key in data) {
            if (Object.prototype.toString.call(data[key]) === '[object Array]') {
                for (let i in data[key]) {
                    let currentObjectName = data[key][i].label == key ? key : `${key}_${data[key][i].label}`;
                    let object = p.object ? `${p.object}.${currentObjectName}` : currentObjectName;
                    let children = get(data[key][i], { object });
                    if (!children.length) {
                        continue;
                    }
                    children = children.filter((item) => {
                        return !item.property
                    })
                    let treeItem = {
                        label: deviceObjectParseByType(currentObjectName, type),
                        object: object,
                        nodeKye: object,
                        children: children
                    };
                    tree.push(treeItem);
                }
                continue;
            }
            if (Object.prototype.toString.call(data[key]) === '[object Object]') {
                let currentObjectName = key;
                let object = p.object ? `${p.object}.${currentObjectName}` : currentObjectName;
                let children = get(data[key], { object });
                if (!children.length) {
                    continue;
                }
                children = children.filter((item) => {
                    return !item.property
                })
                let treeItem = {
                    label: deviceObjectParseByType(currentObjectName, type),
                    object: object,
                    nodeKye: object,
                    children: children
                };
                tree.push(treeItem);
                continue;
            }
            let property = propertyConfiglFun(key, p.object);
            if (property) {
                let treeItem = {
                    ...property,
                    label: deviceObjectParseByType(key, type),
                    object: p.object,
                    property: key,
                    nodeKye: `${p.object}.${key}`,
                    children: []
                };
                tree.push(treeItem);
                continue;
            }
        }
        return tree;
    })(data)
}


//获取属性树
export function getPropertyTree(data, propertyConfiglFun = defPropertyConfigFun, type = undefined) {
    return (function get(data, p = { object: '' }) {
        let tree = [];
        for (let key in data) {
            if (Object.prototype.toString.call(data[key]) === '[object Array]') {
                for (let i in data[key]) {
                    let currentObjectName = data[key][i].label == key ? key : `${key}_${data[key][i].label}`;
                    let object = p.object ? `${p.object}.${currentObjectName}` : currentObjectName;
                    let children = get(data[key][i], { object });
                    if (!children.length) {
                        continue;
                    }
                    let treeItem = {
                        label: deviceObjectParseByType(currentObjectName, type),
                        object: object,
                        nodeKye: object,
                        children: children
                    };
                    tree.push(treeItem);
                }
                continue;
            }
            if (Object.prototype.toString.call(data[key]) === '[object Object]') {
                let currentObjectName = key;
                let object = p.object ? `${p.object}.${currentObjectName}` : currentObjectName;
                let children = get(data[key], { object });
                if (!children.length) {
                    continue;
                }
                let treeItem = {
                    label: deviceObjectParseByType(currentObjectName, type),
                    object: object,
                    nodeKye: object,
                    children: children
                };
                tree.push(treeItem);
                continue;
            }
            let property = propertyConfiglFun(key, p.object);
            if (property) {
                let treeItem = {
                    ...property,
                    label: deviceObjectParseByType(key, type),
                    object: p.object,
                    property: key,
                    nodeKye: `${p.object}.${key}`,
                    children: []
                };
                tree.push(treeItem);
                continue;
            }
        }
        return tree;
    })(data)
}

//获取对象数据
export function getObjectData(data, object) {
    if (!object) {
        return data
    }
    let objectArr = object.split('.');
    let _data = data;
    (function get(objectArr) {
        for (let i in objectArr) {
            if (objectArr[i].includes("_")) {
                let _objectArr = objectArr[i].split("_");
                get(_objectArr);
                continue;
            }
            if (Object.prototype.toString.call(_data) === '[object Array]') {
                _data = _data.find((item) => {
                    return item.label == objectArr[i];
                }) || {};
                continue;
            }
            if (Object.prototype.toString.call(_data[objectArr[i]]) === '[object Array]') {
                let __data = _data[objectArr[i]].find((item) => {
                    return item.label == objectArr[i];
                });
                if (__data) {
                    _data = __data;
                    continue;
                }
            }
            _data = _data ? _data[objectArr[i]] || {} : {};
        }
    })(objectArr)
    if (_data) {
        _data.timestamp = data.timestamp;
    }
    return _data;
}

//获取属性数据
export function getPropertyData(data, object, property,) {
    try {
        let _data = getObjectData(data, object);
        return _data[property]
    } catch (error) {
        return null
    }
}




//mqtt提交数据
export function demandPublish(imei, supplyData) {
    let data = {
        version: "0.1.0",
        timestamp: Date.now(),
        ...supplyData
    }
    let subscribeTopic = `F/+/+/+/+/+/+/+/+/${imei}/Reply`;
    let unsubscribe = () => {
        mqtt.clientServer.unsubscribe(subscribeTopic);
    }
    return {
        unsubscribe,
        publish: () => {
            return new Promise((reslove, reject) => {
                let timeout = null
                console.log('subscribeTopic',subscribeTopic);
               
                
                mqtt.clientServer.subscribe(subscribeTopic);
                mqtt.clientServer.on('message', (topic, message) => {
                    if (!topic.includes(`${imei}/Reply`)) {
                        return;
                    }
                    clearTimeout(timeout);
                    unsubscribe();
                    reslove(JSON.parse(message.toString()));
                });
                mqtt.clientServer.publish(`F/0/0/0/0/0/0/0/0/${imei}/Demand`, JSON.stringify(
                    data),{qos: 2});
                timeout = setTimeout(() => {
                    unsubscribe();
                    reslove({
                        command: 'timeout'
                    })
                }, 12000);
            })
        }
    }
}

//时长
export function toDuration(value) {
    if (!value) {
        return 'N/A'
    }
    let S = Math.floor(value % 60);
    let M = Math.floor((value / 60) % 60);
    let H = Math.floor((value / 60 / 60) % 60);
    let D = Math.floor((value / 60 / 60 / 60) % 60);
    return `${D > 0 ? D + '天' : ''}${H > 0 ? H + '时' : ''}${M > 0 ? M + '分' : ''}${S > 0 ? S + '秒' : ''}`
}

export function getDataTree(data, objectConfigFun = () => { return true }, propertyConfiglFun = (labelName) => { return labelName }) {
    return (function get(data, p = { object: '' }) {
        let tree = [];
        for (let key in data) {
            if (Object.prototype.toString.call(data[key]) === '[object Array]') {
                for (let i in data[key]) {
                    let currentObjectName = data[key][i].label == key ? key : `${key}_${i}`;
                    let object = p.object ? `${p.object}.${currentObjectName}` : currentObjectName;
                    let children = get(data[key][i], { object });
                    if (!children.length) {
                        continue;
                    }
                    let treeItem = {
                        label: objectConfigFun(currentObjectName),
                        object: object,
                        nodeKye: object,
                        children: children
                    };
                    tree.push(treeItem);
                }
                continue;
            }
            if (Object.prototype.toString.call(data[key]) === '[object Object]') {
                let currentObjectName = key;
                let object = p.object ? `${p.object}.${currentObjectName}` : currentObjectName;
                let children = get(data[key], { object });
                if (!children.length) {
                    continue;
                }
                let treeItem = {
                    label: objectConfigFun(currentObjectName),
                    object: object,
                    nodeKye: object,
                    children: children
                };
                tree.push(treeItem);
                continue;
            }
            let property = propertyConfiglFun(key);
            if (property) {
                let treeItem = {
                    ...property,
                    object: p.object,
                    property: key,
                    nodeKye: p.object ? `${p.object}.${key}` : key,
                    children: []
                };
                tree.push(treeItem);
                continue;
            }
        }
        return tree;
    })(data)
}

export function numToFixed(val, num = 2) {
    return val ? parseFloat(val).toFixed(num) : val
}

// 字符串脱敏
export function strTuomin(str = "", front = 4, back = 4) {
    return str.replace(new RegExp(`^(.{${front}}).*(.{${back}})$`), '$1****$2');
}