<template>
  <div :visible.sync="visible">
    
    <div class="c-set-body">
      <div class="c-set-menu-box">
        <div style="flex: 1"></div>
        <div class="c-menu-buttons">
          <div class="c-menu-item">
            <el-button type="primary" size="mini" @click="onSubmit">保存</el-button>
          </div>
          <div class="c-menu-item">
            <el-button type="danger" size="mini" @click="onClose">关闭</el-button>
          </div>
        </div>
      </div>
      <div class="c-set-barriers">
        <!-- 工具栏 -->
        <div class="c-set-tool-box">
          <div
            class="c-tool-item"
            :class="[item.value == toolValue ? 'active' : '']"
            @click="onToolClick(item)"
            v-for="item in tools"
            :key="item.value"
          >
            <div class="c-tool-item-icon"><i :class="[item.icon]" /></div>
            <div>{{ item.label }}</div>
          </div>
        </div>
        <!-- 工作区 -->
        <div class="c-set-svg-box">
          <div class="c-set-svg-body">
            <div class="c-svg-block" :style="{ width: `${width * scale}px`, height: `${height * scale}px` }">
              <svg
                ref="svg"
                :viewBox="`0 0 ${canvasConfig.width} ${canvasConfig.height}`"
                class="c-set-svg c-zoom"
                :style="{ width: `${width * scale}px`, height: `${height * scale}px` }"
              ></svg>
              <div class="bei">
                <div><i class="el-icon-top"></i></div>
                <div>北</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 属性栏 -->
        <div class="c-bar c-set-property-box">
          <div v-if="cuObject">
            <div class="c-point-item" style="width: 100%">
              <div style="color: #fff">{{ cuObject.duid }}</div>
            </div>
            <div class="c-point-item" style="width: 100%" v-if="cuObject.status != 'online'">
              <div style="color: #f56c6c">设备离线，不能调整</div>
            </div>
            <div class="c-point-li" style="margin-top: 15px">
              <div class="c-point-title">相对坐标</div>
              <div class="c-point-item" style="width: 100%">
                <div>X</div>
                <div class="input">
                  <input
                    v-model.number="cuObject.x"
                    :readonly="cuObject.status != 'online'"
                    @change="onInputChangeRelative"
                    active-color="#F56C6C"
                    inactive-color="#409EFF"
                  />
                </div>
              </div>
              <div class="c-point-item" style="width: 100%">
                <div>Y</div>
                <div class="input">
                  <input
                    v-model.number="cuObject.y"
                    :readonly="cuObject.status != 'online'"
                    @change="onInputChangeRelative"
                    active-color="#F56C6C"
                    inactive-color="#409EFF"
                  />
                </div>
              </div>
            </div>
            <div class="c-point-li">
              <div class="c-point-title">绝对坐标</div>
              <div class="c-point-item" style="width: 100%">
                <div>X</div>
                <div class="input">
                  <input
                    v-model.number="cuObject.dx"
                    :readonly="cuObject.status != 'online'"
                    @change="onInputChangeAbsolute"
                    active-color="#F56C6C"
                    inactive-color="#409EFF"
                  />
                </div>
              </div>
              <div class="c-point-item" style="width: 100%">
                <div>Y</div>
                <div class="input">
                  <input
                    v-model.number="cuObject.dy"
                    :readonly="cuObject.status != 'online'"
                    @change="onInputChangeAbsolute"
                    active-color="#F56C6C"
                    inactive-color="#409EFF"
                  />
                </div>
              </div>
            </div>
            <div class="c-point-buttons">
              <div>
                <el-button
                  size="mini"
                  v-if="substanceDot.x != cuObject.dx && substanceDot.y != cuObject.dy"
                  type="danger"
                  @click="onSetsubStanceDot"
                  >设为参照物</el-button
                >
                <el-button size="mini" v-if="cuObject.duid != selfDevice.duid" type="danger" @click="onDeviceDel">删除设备</el-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 对象栏 -->
        <div class="c-bar">
          <div
            class="c-object-item"
            :class="[(cuObject ? cuObject.duid : '') == item.duid ? 'active' : '']"
            :style="{
              color: item.duid == selfDevice.duid ? '#d79e20' : item.status == 'online' ? '#67C23A' : ''
            }"
            @click="onObjectItem(item, index)"
            style="width: 100%"
            v-for="(item, index) in list"
            :key="item.duid"
          >
            {{ item.duid }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SVG } from '@svgdotjs/svg.js';
import screen from "../../../api/modules/screen";
// import { demandPublish } from '../until';

export default {
  // inject: ['imei'],
  props: ["visible", "title", "workData", "deviceType",'svgTimes','selfDevice','statusArr','statusDatas'],
  data() {
    return {
      list: [],
      tools: [
        { label: '选择', icon: 'el-icon-top-left', value: 'select' },
        { label: '放大', icon: 'el-icon-zoom-in', value: 'zoomin' },
        { label: '缩小', icon: 'el-icon-zoom-out', value: 'zoomout' }
      ],
      toolValue: 'select',
      isDraw: true,
      svg: null,
      newDraw: null,
      newDrawPoints: null,
      cuObject: null,
      substanceDot: {
        x: 300,
        y: 200
      },
      scale: 1,
      // selfDevice: null,
      vicinageDevIces: null,
      overlookConfig: {
        x: 70,
        y: 70,
        radius: 80
      },
      width: 600,
      height: 400,
      canvasConfig: {
        width: 600,
        height: 400
      }
    };
  },
  mounted() {
    this.init();
    // this.status()
  },
  watch: {
    list() {
      
      this.reset();
    },
    toolValue() {
      this.cuObject = null;
    },
    cuObject(val) {
      this.cuObjectSvg ? this.cuObjectSvg.remove() : null;
      this.drawSelect();
    },
    substanceDot() {
      this.coordLineSvg ? this.coordLineSvg.remove() : null;
      this.drawCoordLine();
    }
  },
  computed: {
    isPolygon() {
      return this.toolValue == 'polygon';
    },
    isSelect() {
      return this.toolValue == 'select';
    }
  },
  methods: {
    
    async init() {
      // let res = await this.getVicinageDevIces();
     
      // if (!res) {
      //   return;
      // }
      this.svg = SVG(this.$refs.svg);
      let list =[this.selfDevice, ...this.svgTimes]
      let xarr = [],
        yarr = [],
        armlenth = [];
      list.forEach((item) => {
       this.statusArr.map(_=>{
        if(_.duid==item.duid){
          item.status =_.status
        }
       })
        xarr.push(item.x || 0);
        yarr.push(item.y || 0);
        armlenth.push(item.armlenth || 0);
      });
      let minX = Math.min(...xarr);
      let maxX = Math.max(...xarr);
      let minY = Math.min(...yarr);
      let maxY = Math.max(...yarr);
      let minArmlenth = Math.min(...armlenth);
      let maxArmlenth = Math.max(...armlenth);
      this.canvasConfig = {
        width:
          Math.abs(maxX - minX) + maxArmlenth * 2.1 < this.canvasConfig.width
            ? this.canvasConfig.width
            : Math.abs(maxX - minX) + maxArmlenth * 2.1,
        height:
          Math.abs(maxY - minY) + maxArmlenth * 2.1 < this.canvasConfig.height
            ? this.canvasConfig.height
            : Math.abs(maxY - minY) + maxArmlenth * 2.1
      };
      this.substanceDot = {
        x: Math.abs(minX) + maxArmlenth * 1.05,
        y: Math.abs(minY) + maxArmlenth * 1.05
      };
      this.width = this.canvasConfig.width;
      this.height = this.canvasConfig.height;
      list.map((item) => {
        let coord = this.coordTransformToDxy(item);
        item.dx = coord.x;
        item.dy = coord.y;
      });
      this.list = list;
    },
    reset() {
      this.svg.clear();
      this.list.forEach((item, index) => {
        console.log('item',item);
        
        this.drawVTower(item, index);
      });
      this.drawSelect();
      this.drawCoordLine();
    },
    //获取设备列表
    async getVicinageDevIces() {
      let loading = this.$loading();
      let neighboursRes = await this.$api.getVicinageDevIces({
        DeviceImei: this.imei
      });
      loading.close();
      if (!neighboursRes || neighboursRes.code != 200) {
        await this.$alert('请求错误，请稍后重试！');
        this.$emit('close');
        return;
      }
      //获取附近设备在线状态
      loading = this.$loading();
      let onlineStateRes = await this.$api.getVicinageDevIcesOnlineState({
        DeviceImei: this.imei
      });
      loading.close();
      let onlineState = onlineStateRes.reslut?.neighbours || [];
      let self = neighboursRes.reslut?.self || { x: 0, y: 0 };
      let neighbours = neighboursRes.reslut?.neighbours;
      // neighbours = [
      //   {
      //     armlenth: 45,
      //     armwidth: 1,
      //     craneheight: 90,
      //     duid: 'SuVABeo9_4v7Bhfn1BgksA==ROXX',
      //     label: null,
      //     safedistance: 1,
      //     type: 96,
      //     x: null,
      //     y: null,
      //     status:'online'
      //   }
      // ];
      if (!neighbours || !neighbours.length) {
        await this.$alert('未获取到附近设备');
        this.$emit('close');
        return;
      }

      neighbours.map((item) => {
        let _item = onlineState.find((_item) => {
          return _item.duid == item.duid;
        });
        if (_item) {
          item.status = _item.status;
        } else {
          item.status = 'offline';
        }
      });
      self.status = 'online';
      this.selfDevice = self;
      this.vicinageDevIces = neighbours;
      return true;
    },
    onObjectItem(item, index) {
      this.cuObject = item;
    },
    funvicinageDevIces() {
      this.list.map((item) => {
        let coord = this.coordTransform(item);
        item.dx = coord.x;
        item.dy = coord.y;
      });
    },
    onInputChange() {
      this.list = [].concat(this.list);
    },
    onInputChangeAbsolute() {
      let { cuObject, substanceDot } = this;
      cuObject.x = cuObject.x != null ? cuObject.dx - substanceDot.x : null;
      cuObject.y = cuObject.y != null ? cuObject.dy - substanceDot.y : null;
      this.onInputChange();
    },
    onInputChangeRelative() {
      let { cuObject, substanceDot } = this;
      cuObject.dx = cuObject.x || 0 + substanceDot.x;
      cuObject.dy = cuObject.y || 0 + substanceDot.y;
      this.onInputChange();
    },
    onToolClick(tool) {
      this[`${tool.value}ToolClick`] ? this[`${tool.value}ToolClick`](tool) : (this.toolValue = tool.value);
    },
    zoominToolClick() {
      if (this.scale >= 4) {
        return;
      }
      this.scale *= 1.2;
    },
    zoomoutToolClick() {
      if (this.scale <= 0.25) {
        return;
      }
      this.scale /= 1.2;
    },
    onClick(e) {
      this[`${this.toolValue}Click`] && this[`${this.toolValue}Click`](e);
    },
    async onListDel() {
      try {
        // await this.$confirm('确定要删除吗?');
      } catch (error) {
        return;
      }
      let index = this.list.findIndex((item) => {
        return item.id == this.cuObject.id;
      });
      if (index >= 0) {
        this.list.splice(index, 1);
      }
      this.cuObject = null;
    },
    drawSelect() {
      let { cuObject } = this;
      if (!cuObject) {
        return;
      }
      console.log('cuObject',cuObject);
      
      this.cuObjectSvg = this.svg.circle().radius(cuObject.armlenth).attr({
        fill: 'rgba(214,185,32,.1)',
        cx: cuObject.dx,
        cy: cuObject.dy
      });
    },
    drawTower() {
      let { selfDevice } = this;
     
      
      let group = this.svg.group();
      group.circle().radius(selfDevice.armlenth).attr({
        fill: 'rgba(214,185,32,.1)',
        'stroke-width': 1,
        stroke: '#d79e20',
        cx: selfDevice.dx,
        cy: selfDevice.dy
      });
      group
        .rect(selfDevice.armwidth, selfDevice.armlenth)
        .attr({ x: selfDevice.dx - selfDevice.armwidth / 2, y: selfDevice.dy - selfDevice.armlenth, fill: '#d79e20' });
      group
        .rect(selfDevice.armwidth, selfDevice.armwidth)
        .attr({ x: selfDevice.dx - selfDevice.armwidth / 2, y: selfDevice.dy, fill: '#78530a' });
    },
    // 画附近塔
    drawVTower(item) {
      let group = this.svg.group();
     
      this.selfDevice.status = this.statusDatas
      
      let color = this.selfDevice.duid == item.duid ? '#d79e20' : item.status == 'online' ? '#67C23A' : '#aaaaaa';
      group.circle().radius(item.armlenth).attr({
        fill: 'transparent',
        'stroke-width': 1,
        stroke: color,
        cx: item.dx,
        cy: item.dy
      });
      group.rect(item.armwidth, item.armlenth).attr({ x: item.dx - item.armwidth / 2, y: item.dy - item.armlenth, fill: color });
      group.rect(item.armwidth, item.armwidth).attr({ x: item.dx - item.armwidth / 2, y: item.dy, fill: 'rgba(0,0,0,.5)' });
      group.on('click', () => {
        this.cuObject = item;
       
        
      });
    },
    drawCoordLine() {
      let { substanceDot, canvasConfig } = this;
   
      
      let group = this.svg.group();
      group
        .line(substanceDot.x, 0, substanceDot.x, canvasConfig.height)
        .attr({ stroke: '#409EFF', 'stroke-width': 1, 'stroke-dasharray': '3 2' });
      group
        .line(0, substanceDot.y, canvasConfig.width, substanceDot.y)
        .attr({ stroke: '#409EFF', 'stroke-width': 1, 'stroke-dasharray': '3 2' });
      this.coordLineSvg = group;
    },
    // 设置参照物
    async onSetsubStanceDot() {
      
      // try {
      //   await this.$confirm('确定要设置为参照对象吗');
      // } catch (error) {
      //   return;
      // }
      let { cuObject } = this;
      this.substanceDot = {
        x: cuObject.dx,
        y: cuObject.dy
      };
      this.list = this.list.map((item) => {
        item.x = item.x != null ? item.dx - this.substanceDot.x : null;
        item.y = item.y != null ? item.dy - this.substanceDot.y : null;
        return item;
      });
    },
    //计算参照坐标
    coordTransform(device) {
      let { substanceDot } = this;
      return {
        x: substanceDot.x + device.x || 0,
        y: substanceDot.y + device.y || 0
      };
    },
    coordTransformToDxy(device) {
      let { substanceDot } = this;
      return {
        x: substanceDot.x + device.x || 0,
        y: substanceDot.y + device.y || 0
      };
    },
    async onDeviceDel() {
      try {
        // await this.$confirm('确定要删除设备吗');
      } catch (error) {
        return;
      }
      let index = this.list.findIndex((item) => {
        return item.duid == this.cuObject.duid;
      });
      this.list.splice(index, 1);
      this.cuObject = null;
    },
    async onSubmit() {
      try {
        // await this.$confirm('确定要保存吗?');
      } catch (error) {
        return;
      }
      let loading = this.$loading();
      let res = await this.submit();
    
      loading.close();
      if (res?.command != 'Succeed') {
        this.$message(res?.command == 'timeout' ? '请求超时，请稍后重试。' : '保存失败');
        return;
      }
      this.$message('保存成功');
    },
    // 提交
    submit() {
      let { selfDevice } = this;
      let self = null,
        neighbours = [];
      this.list.map((item, index) => {
        let data = {
          ...item
        };
        delete data.dx;
        delete data.dy;
        delete data.status;
        if (item.duid == selfDevice.duid) {
          self = data;
        } else {
          neighbours.push(data);
        }
      });
      let supplyData = {
        command: 'CfgVicinity',
        self,
        neighbours
      };
    
      this.mqttSubmit = demandPublish(this.imei, supplyData);
      return this.mqttSubmit.publish();
    },
    //关闭
     onClose() {
       this.$emit('handleClose');
      // try {
      //   await this.$confirm('确定要关闭吗?');
      // } catch (error) {
      //   return;
      // }
     
    }
  }
};
</script>
<style lang="less" scoped>
.c-set-body {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #282d53;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.c-set-menu-box {
  display: flex;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  align-items: center;
  padding: 0 20px;
  .c-menu-buttons {
    display: flex;
    .c-menu-item + .c-menu-item {
      margin-left: 10px;
    }
  }
}
.c-bar {
  width: 230px;
  background-color: rgba(0, 0, 0, 0.2);
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.c-object-item {
  font-size: 12px;
  box-sizing: border-box;
  padding: 0px 10px;
  height: 40px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #a1a1a1;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.2);
  }
  & + .c-object-item {
    border-top: solid 1px rgba(0, 0, 0, 0.2);
  }
}
.c-set-barriers {
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  .c-set-property-box {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: #a1a1a1;
    padding: 5px;
    width: 220px;
    box-sizing: border-box;
    .c-point-buttons {
      padding: 10px;
      text-align: right;
    }
    .c-point-li {
      padding: 5px;
    }
    .c-point-title {
      font-size: 12px;
      padding: 0 10px;
      margin-bottom: 5px;
    }
    .c-point-box {
      display: flex;
    }
    .c-point-item {
      padding: 0 10px;
      width: 50%;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      align-items: center;
      font-size: 12px;
      .input {
        flex: 1;
        overflow: hidden;
        padding-left: 5px;
        input {
          border: none;
          width: 100%;
          height: 26px;
          line-height: 26px;
          font-size: 12px;
          box-sizing: border-box;
          padding: 0 5px;
          background-color: rgba(0, 0, 0, 0.2);
          color: #fff;
          outline: none;
          &:active {
            border: none;
          }
        }
      }
      & + .c-point-item {
        margin-top: 10px;
      }
    }
  }

  .c-set-svg-box {
    flex: 1;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .c-set-svg-body {
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
    white-space: nowrap;
    &::before {
      display: inline-block;
      height: 100%;
      width: 0;
      content: '';
      vertical-align: middle;
    }
  }
  .c-svg-block {
    display: inline-block;
    min-width: 200px;
    min-height: 150px;
    transition: all 0.5s;
    vertical-align: middle;
    position: relative;
    .bei {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 10px;
      color: #409eff;
      text-align: center;
      i {
        font-size: 18px;
      }
    }
  }
  .c-set-svg {
    transition: all 0.5s;
    background-color: #fff;
  }
  .c-set-tool-box {
    height: 100%;
    width: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    .c-tool-item {
      text-align: center;
      padding: 10px;
      box-sizing: border-box;
      font-size: 10px;
      color: #a1a1a1;
      cursor: pointer;
      .c-tool-item-icon {
        font-size: 24px;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &.active {
        background-color: rgba(0, 0, 0, 0.2);
        color: #ccc;
      }
    }
  }
}
</style>