import mqtt from "mqtt";

let client = null;
export function createConnect(topic, callback) {
  client = mqtt.connect(`ws://106.15.63.225:8085`);

  client.on("connect", (e) => {
    console.log(e, "mqtt连接成功");
    if (typeof topic == "string") {
      // 订阅一个topic
      client.subscribe(topic, { qos: 2 }, (err) => {
        if (!err) {
          console.log("消息订阅成功", err);
        } else {
          console.log("消息订阅失败");
        }
      });
    } else {
      // 数组格式，订阅多个topic
      topic.forEach((item) => {
        client.subscribe(item, { qos: 2 }, (err) => {
          if (!err) {
            console.log("消息订阅成功!!!");
          } else {
            console.log("消息订阅失败");
          }
        });
      });
    }
  });

  client.on("message", (topicres, message) => {
   
    
    
    if (callback) {
      callback(topicres, JSON.parse(message.toString()));
    }
    // console.log('收到来自',topicres) 
    // console.log('消息', JSON.parse(message.toString()))
  });
  client.on("error", (err) => {
    console.log("mqtt链接失败", err);
  });
  client.on("disconnect", (back) => {
    console.log("mqtt链接失败2", back);
  });

}
export function subscribeTopic(topic) {
  client.subscribe(topic, { qos: 2 }, (err) => {
    if (!err) {
      console.log("消息订阅成功");
    } else {
      console.log("消息订阅失败");
    }
  });
}

export function endConnect() {
  
  if (client) {
  
    // client.unsubscribe(topic,(err)=>{
    //     if(err){
    //         console.log(err)
    //     }else {
    //         console.log('Unsubscribe')
    //     }
    // })
    client.end(true, () => {
     
      console.log("clientServer已关闭");
      client = null;
    });
  }
}
