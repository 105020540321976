<template>
  <div class="realData">
    <!-- <div class="titleBoxs">
        可视化数据分析
    </div> -->
    <div class="main-box">
      <div class="chart-box" ref="monthWarn"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getFugitiverStateData } from "../../../components/screen/chartOption";

import { turnTimes,turnDayTime } from "../../../utils/tool";
export default {
  components: {},
  props: ["realTimeData",'times'],
  data() {
    return {
      chartMonthWarn: null,
      timer: null,
      time: [],
      windSpeed: [],
      pm10: [],
      noise: [],
      temp: [],
      humidity: [],
    };
  },
  watch: {
    realTimeData: {
      handler(val) {
        if (val) {
          this.initCarTrendChar(val);
        
        }
      },
    },
  },

  mounted() {
    this.initCarTrendChar(0);
  },
  methods: {
    async initCarTrendChar(realTimeData) {
      if(realTimeData){
        if (this.time.length > 15) {
     console.log('realTimeDatas',realTimeData);
     
          
        this.time= this.time.slice(10);
       this.windSpeed=  this.windSpeed.slice(10);
        this.pm10=this.pm10.slice(10);
       this.noise= this.noise.slice(10);
       this.temp= this.temp.slice(10);
        this.humidity=this.humidity.slice(10);
      }
          this.time.push(turnDayTime(this.times))
        this.windSpeed.push(realTimeData.windSpeed)
        this.pm10.push( realTimeData.pm10)
        this.noise.push( realTimeData.noise)
        this.temp.push(realTimeData.temp)
        this.humidity.push(realTimeData.humidity)
        if (!this.chartMonthWarn) {
        this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
      }
     
      const option = getFugitiverStateData(
          this.windSpeed,
        this.pm10,
        this.noise,
        this.temp,
        this.humidity,
        this.time
      );

      this.chartMonthWarn.setOption(option);
      }else{
        if (this.time.length > 15) {
     
          
     this.time= this.time.slice(10);
    this.windSpeed=  this.windSpeed.slice(10);
     this.pm10=this.pm10.slice(10);
    this.noise= this.noise.slice(10);
    this.temp= this.temp.slice(10);
     this.humidity=this.humidity.slice(10);
   }
       this.time.push(turnDayTime(0))
     this.windSpeed.push(0)
     this.pm10.push(0)
     this.noise.push( 0)
     this.temp.push(0)
     this.humidity.push(0)
     if (!this.chartMonthWarn) {
     this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
   }
  
   const option = getFugitiverStateData(
       this.windSpeed,
     this.pm10,
     this.noise,
     this.temp,
     this.humidity,
     this.time
   );

   this.chartMonthWarn.setOption(option);
      }
      
   

  
    },
  },
};
</script>

<style lang="less" scoped>
.realData {
  height: 100%;
  .titleBoxs {
    background-image: url("../../../assets/images/screen/newTitleBox.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:10%;

    background-position: center;
    color: #FFFFFF;
    padding-left: 20px;
  //  margin-bottom: 10px;
    
  }
  .main-box {
    height: 100%;
    width: 100%;
    .chart-box {
      width: 100%;
     height: 100%;
     
    }
  }
}
</style>
