<template>
    <div class="container">
      <!-- 头部 -->
      <div class="heard">
        <screendeilHead
          :title="title"
          :totalTime="totalTime"
          :deviceOnline="deviceOnline"
          :deviceNo="deviceNo"
          :workData="workData"
          :showWarm="showWarm"
          :statusOnline="statusOnline"
          :versioncc="versioncc"
          :deviceType="deviceType"
          :realTimeData="realTimeData"
          :svgTimes="svgTimes"
          :selfDevice="selfDevice"
        ></screendeilHead>
      </div>
      <!-- 主体 -->
      <div class="main">
        <div class="leftMain">
          <div class="leftTop">
            <div class="realBox">
                <windSpeed style="width: 100%" :realTimeData="realTimeData"></windSpeed>
                <noise style="width: 100%" :realTimeData="realTimeData"></noise>
                <pm2 style="width: 100%" :realTimeData="realTimeData"></pm2>
                <pm10 style="width: 100%" :realTimeData="realTimeData"></pm10>
                <temp style="width: 100%" :realTimeData="realTimeData"></temp>
                <humidity style="width: 100%" :realTimeData="realTimeData"></humidity>
                <atmosphere style="width: 100%" :realTimeData="realTimeData"></atmosphere>
                <tsp style="width: 100%" :realTimeData="realTimeData"></tsp>
              <!-- <load style="width: 100%" :realTimeData="realTimeData"></load>
              <MomentratioDel
                v-if="deviceType == 0"
                style="width: 100%"
                :realTimeData="realTimeData"
              ></MomentratioDel>
              <Slide
                style="width: 100%"
                :realTimeData="realTimeData"
                v-if="deviceType != 2"
              ></Slide>
              <RotaTionDel
                v-if="deviceType == 0"
                style="width: 100%"
                :realTimeData="realTimeData"
              ></RotaTionDel>
              <HeightTionDel
                style="width: 100%"
                :realTimeData="realTimeData"
              ></HeightTionDel>
              <LuffingDel
                v-if="deviceType == 0"
                style="width: 100%"
                :realTimeData="realTimeData"
              ></LuffingDel>
              <WindDel style="width: 100%" :realTimeData="realTimeData"></WindDel>
              <Horizontal
                v-if="deviceType != 2"
                style="width: 100%"
                :realTimeData="realTimeData"
              ></Horizontal>
              <traliveDel
                v-if="deviceType == 2"
                style="width: 100%"
                :realTimeData="realTimeData"
              ></traliveDel> -->
            </div>
          </div>
          <div class="leftModel">
            <div class="leftdel">
              <div class="henxian">
                <div class="box8">
                  <div class="box9">
                    <div>
                      <img
                        src="../../../assets/images/screen/sanjiaoxin.png"
                        alt=""
                      />
                    </div>
                    <div class="title">设备信息</div>
                  </div>
                  <div class="box10">
                    <img
                      src="../../../assets/images/screen/duobianx.png"
                      alt=""
                    />
                  </div>
                  <div
                    class="title"
                    style="margin-right: 10px; cursor: pointer"
                    @click="svgClick"
                    v-if="deviceType == 0"
                  >
                    更多
                  </div>
                </div>
                <div class="henxianbox"></div>
              </div>
  
              <div class="simulatedBox">
                <simulated
                  :title="title"
                  :deviceType="8"
                  :equmentObj="equmentObj"
                 
                ></simulated>
              </div>
              <div class="warnBoxsc">
                <div class="singular" v-for="(item, index) in list" :key="index">
                  <div
                    class="showBox"
                    :class="[item.show == true ? 'showBox2' : 'showBox']"
                  >
                    <div class="test">
                      <div v-if="item.show == false">
                        <img
                          src="../../../assets/images/screen/newnormal.png"
                          alt=""
                        />
                      </div>
                      <div v-if="item.show == true">
                        <img
                          src="../../../assets/images/screen/newpolice.png"
                          alt=""
                        />
                      </div>
                      <div>{{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftdel2 video" ref="videoRef">
              <div class="henxian">
                <div class="box8">
                  <div class="box9">
                    <div>
                      <img
                        src="../../../assets/images/screen/sanjiaoxin.png"
                        alt=""
                      />
                    </div>
                    <div class="title">监控视频</div>
                  </div>
                  <div class="box10">
                    <img
                      src="../../../assets/images/screen/duobianx.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="henxianbox"></div>
              </div>
              <div class="tabs">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane
                    :label="item.cameType"
                    :name="item.cameSn"
                    v-for="(item, index) in videoList"
                    :key="index"
                  >
                  </el-tab-pane>
                </el-tabs>
              </div>
  
              <div class="videoss">
                <ScreenVideo
                  :data="videoInfo"
                  :height="videoHeight"
                  :width="videoWidth"
                  :index="2"
                  class=""
                ></ScreenVideo>
              </div>
            </div>
          </div>
          <div class="leftBottom">
            <div class="leftBottomdel">
              <div class="henxian">
                <div class="box8">
                  <div class="box9">
                    <div>
                      <img
                        src="../../../assets/images/screen/sanjiaoxin.png"
                        alt=""
                      />
                    </div>
                    <div class="title">实时数据</div>
                  </div>
                  <div class="box10">
                    <img
                      src="../../../assets/images/screen/duobianx.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="henxianbox"></div>
              </div>
              <div class="realDatadetailbox">
                <FugitiverealDatadetail
                 
                  :title="title"
                  :realTimeData="realTimeData"
                  :times="times"
                ></FugitiverealDatadetail>
                <!-- <LiftRealDatadetail
                  v-if="deviceType == 1"
                  :title="title"
                  :realTimeData="realTimeData"
                  :times="times"
                ></LiftRealDatadetail>
                <bridgeRealDatadetail
                  v-if="deviceType == 3"
                  :title="title"
                  :realTimeData="realTimeData"
                  :times="times"
                ></bridgeRealDatadetail>
                <doorRealDatadetail
                  v-if="deviceType == 2"
                  :title="title"
                  :realTimeData="realTimeData"
                  :times="times"
                ></doorRealDatadetail> -->
              </div>
            </div>
            <!-- <div class="leftBottomdel2">
              <div class="henxian">
                <div class="box8">
                  <div class="box9">
                    <div>
                      <img
                        src="../../../assets/images/screen/sanjiaoxin.png"
                        alt=""
                      />
                    </div>
                    <div class="title">工况数据</div>
                  </div>
                  <div class="box10">
                    <img
                      src="../../../assets/images/screen/duobianx.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="henxianbox"></div>
              </div>
              <div class="workSetData">
                <workSetData
                  :workData="workData"
                  v-if="deviceType == 0"
                ></workSetData>
                <LiftWorkSetData
                  :workData="workData"
                  v-if="deviceType == 1"
                ></LiftWorkSetData>
                <bridgeWorkSetData
                  :workData="workData"
                  v-if="deviceType == 3"
                ></bridgeWorkSetData>
                <doorWorkSetData
                  :workData="workData"
                  v-if="deviceType == 2"
                ></doorWorkSetData>
              </div>
            </div> -->
          </div>
        </div>
        <div class="rightMain">
          <div class="rightTop">
            <userInfo :projId="projId"   :title="title"></userInfo>
          </div>
          <div class="rightBottom" ref="rightBottomref">
            <div class="henxian">
              <div class="box8">
                <div class="box9">
                  <div>
                    <img
                      src="../../../assets/images/screen/sanjiaoxin.png"
                      alt=""
                    />
                  </div>
                  <div class="title">今日告警</div>
                </div>
                <div class="box10">
                  <img src="../../../assets/images/screen/duobianx.png" alt="" />
                </div>
              </div>
              <div class="henxianbox"></div>
            </div>
            <div class="warndetail">
              <detailToDayWarn
                :title="title"
                :tableHeight="tableHeight"
              ></detailToDayWarn>
            </div>
          </div>
        </div>
      </div>
      <svgDialog
        :visible.sync="showDialog"
        @close="colseDialog"
        @done="doneDialog"
        :realTimeData="realTimeData"
        :workData="workData"
        v-if="deviceType == 0"
        :svgTimes="svgTimes"
      ></svgDialog>
    </div>
  </template>
  
  <script>
  // import mqtt from "mqtt";
  import screendeilHead from "../../../components/screen/fiveEqument/screendeilHead.vue";
  import load from "../../../components/screen/fiveEqument/load.vue";
  import windSpeed from "../../../components/screen/fiveEqument/windSpeed.vue";
  import noise from "../../../components/screen/fiveEqument/noise.vue";
  import pm2 from "../../../components/screen/fiveEqument/pm2.vue";
  import pm10 from "../../../components/screen/fiveEqument/pm10.vue";
  import temp from "../../../components/screen/fiveEqument/temp.vue";
  import tsp from "../../../components/screen/fiveEqument/tsp.vue";
  import atmosphere from "../../../components/screen/fiveEqument/atmosphere.vue";
  import humidity from "../../../components/screen/fiveEqument/humidity.vue";
  import MomentratioDel from "../../../components/screen/fiveEqument/MomentratioDel.vue";
  import realDatadetail from "../../../components/screen/fiveEqument/realDatadetail.vue";
  import FugitiverealDatadetail from "../../../components/screen/fiveEqument/FugitiverealDatadetail.vue";
  import LiftRealDatadetail from "../../../components/screen/fiveEqument/LiftRealDatadetail.vue";
  import bridgeRealDatadetail from "../../../components/screen/fiveEqument/bridgeRealDatadetail.vue";
  import doorRealDatadetail from "../../../components/screen/fiveEqument/doorRealDatadetail.vue";
  import simulated from "../../../components/screen/fiveEqument/simulated.vue";
  import ScreenVideo from "../../../components/screen/fiveEqument/ScreenVideo.vue";
  import userInfo from "../../../components/screen/fiveEqument/userInfo.vue";
  import workSetData from "../../../components/screen/fiveEqument/workSetData.vue";
  import LiftWorkSetData from "../../../components/screen/fiveEqument/LiftWorkSetData.vue";
  import doorWorkSetData from "../../../components/screen/fiveEqument/doorWorkSetData.vue";
  import bridgeWorkSetData from "../../../components/screen/fiveEqument/bridgeWorkSetData.vue";
  import detailToDayWarn from "../../../components/screen/fiveEqument/detailToDayWarn.vue";
  import Slide from "../../../components/screen/fiveEqument/SlideDel.vue";
  import RotaTionDel from "../../../components/screen/fiveEqument/RotaTionDel.vue";
  import HeightTionDel from "../../../components/screen/fiveEqument/HeightTionDel.vue";
  import LuffingDel from "../../../components/screen/fiveEqument/LuffingDel.vue";
  import WindDel from "../../../components/screen/fiveEqument/WindDel.vue";
  import Horizontal from "../../../components/screen/fiveEqument/HorizontalDel.vue";
  import svgDialog from "../../../components/screen/fiveEqument/svgDialog.vue";
  import screen from "../../../api/modules/screen";
  import traliveDel from "../../../components/screen/fiveEqument/traliveDel.vue";
  import { createConnect, endConnect, subscribeTopic } from "../../../api/mqtt";
  import { createConnects, endConnects } from "../../../api/mqtt2";
  export default {
    components: {
      screendeilHead,
      load,
      MomentratioDel,
      realDatadetail,
      simulated,
      ScreenVideo,
      userInfo,
      workSetData,
      detailToDayWarn,
      Slide,
      RotaTionDel,
      HeightTionDel,
      LuffingDel,
      WindDel,
      Horizontal,
      LiftWorkSetData,
      LiftRealDatadetail,
      bridgeRealDatadetail,
      bridgeWorkSetData,
      svgDialog,
      doorRealDatadetail,
      traliveDel,
      doorWorkSetData,
      FugitiverealDatadetail,
      windSpeed,
      noise,
      pm2,
      pm10,
      temp,
      humidity,
      atmosphere,
      tsp
    },
    data() {
      return {
        showDialog: false,
        timer: null,
        timer2: null,
        showWarm: false,
        timers:null,
        list: [
          { name: "PM2.5", id: 1, show: false, type: "weight" },
          { name: "PM10", id: 2, show: false, type: "moment" },
          { name: "温度", id: 3, show: false, type: "height" },
          { name: "湿度", id: 4, show: false, type: "amplitude" },
          { name: "风速", id: 5, show: false, type: "rotate" },
          { name: "风力", id: 6, show: false, type: "elevation" },
          { name: "气压", id: 7, show: false, type: "wind" },
          { name: "噪声", id: 8, show: false, type: "walk" },
          { name: "TSP监测", id: 9, show: false, type: "barrier" },
          { name: "风向", id: 10, show: false, type: "anticollision" },
        ],
        // list: [
        //   { name: "左禁行区", id: 11, show: false, type: "anticollision" },
        //   { name: "远禁行区", id: 12, show: false, type: "anticollision" },
        //   { name: "高限位", id: 12, show: false, type: "anticollision" },
        //   { name: "远限位", id: 12, show: false, type: "anticollision" },
        //   { name: "左限位", id: 12, show: false, type: "anticollision" },
        //   { name: "倾斜", id: 12, show: false, type: "anticollision" },
        //   { name: "风速", id: 12, show: false, type: "anticollision" },
        //   { name: "左碰撞", id: 12, show: false, type: "anticollision" },
        //   { name: "右碰撞", id: 12, show: false, type: "anticollision" },
        //   { name: "右禁行区", id: 12, show: false, type: "anticollision" },
        //   { name: "近禁行区", id: 12, show: false, type: "anticollision" },
        //   { name: "低限位", id: 12, show: false, type: "anticollision" },
        //   { name: "近限位", id: 12, show: false, type: "anticollision" },
        //   { name: "右限位", id: 12, show: false, type: "anticollision" },
        //   { name: "载重", id: 12, show: false, type: "anticollision" },
        //   { name: "低碰撞", id: 12, show: false, type: "anticollision" },
        //   { name: "右碰撞", id: 12, show: false, type: "anticollision" },
        //   { name: "近碰撞", id: 12, show: false, type: "anticollision" },
        // ],
        value: "",
        projId: "",
        title: "",
        deviceType: "",
        videoInfo: {
          type: 0,
          url: "", //E60695771
        },
        videoHeight: 0,
        videoWidth: 0,
        activeName: "",
        videoList: [],
        projId: "",
        realTimeData: {},
        times: "",
        workData: {},
        videoType: "",
        deviceOnline: "",
        deviceId: "",
        deviceNo: "",
        versioncc: "",
        statusOnline: "",
        totalTime: "",
        tableHeight: 0,
        svgTimes: [],
        selfDevice: {},
        equmentObj:{}
      };
    },
    beforeDestroy() {
      // 清除定时器
      clearInterval(this.timer);
      clearInterval(this.timers);
      endConnect();
      endConnects();
    },
    mounted() {
      const query = this.$route.query;
      console.log("query", query);
      this.typeDel = {
        title: query.title,
        deviceType: query.deviceType,
      };
      this.deviceId = query.deviceId;
      this.projId = query.projId;
      this.deviceType = query.deviceType;
      this.title = query.title;
      this.getDeviecInfos(this.title)
    //   this.title = 'g862SjINUL0wVFEZ4kbaxg==PREX';
  this.timers =  setInterval(()=>{
        this.getRealDatas(this.title)
    },2000)
      this.getRealDatas(this.title)
      this.deviceOnline = query.deviceOnline;
      this.showWarm = Boolean(query.showWarm);
  
      this.videoType = query.type;
      this.deviceNo = query.deviceNo;
      this.getVideoList(this.title, query.type);
      // this.getRealDataList(this.title);
      this.tableHeight = this.$refs.rightBottomref.clientHeight - 50;
      this.getSelectDeviceAlarm(this.title);
   
      this.videoHeight = this.$refs.videoRef.clientHeight + 40;
      this.videoWidth = this.$refs.videoRef.clientWidth;
      console.log(this.videoHeight, this.videoWidth);
   
    },
    methods: {
        getDeviecInfos(deviceId){
            screen.getDeviecInfo({deviceId:deviceId}).then(res=>{
            
              this.equmentObj = res.data
            })
        },
        getRealDatas(deviceId){
            screen.getRealData({deviceId:deviceId}).then(res=>{
                console.log('res实时',res);
                if(res.data&&Object.keys(res.data).length>0){
                    this.realTimeData  = {
                    windSpeed:res.data.windSpeed,
                    pm10:res.data.pm10,
                    noise:res.data.noise,
                    temp:res.data.temp,
                    humidity:res.data.humidity,
                    pm25:res.data.pm25,
                    pm10:res.data.pm10,
                    atmosphere:res.data.atmosphere,
                    tsp:res.data.tsp
                }
                }else{
                    this.realTimeData  = {
                    windSpeed:0,
                    pm10:0,
                    noise:0,
                    temp:0,
                    humidity:0,
                    pm25:0,
                    pm10:0,
                    atmosphere:0,
                    tsp:0
                }
                }
               
             
                this.times = new Date();
            })
        },
      svgClick() {
        this.showDialog = true;
      },
      colseDialog() {
        this.showDialog = false;
      },
      doneDialog() {},
      //订阅工况设置
    
      handleClick(tab, event) {
        screen.getListCamera({ deviceSn: this.title }).then((res) => {
          res.data.map((item) => {
            if (item.cameSn == tab.name) {
              this.videoInfo = {
                type: item.cameraType,
                url: tab.name,
              };
            }
          });
          // this.videoList = res.data;
          // this.activeName = res.data[0].cameSn;
          // this.videoInfo = {
          //   type: res.data[0].cameraType,
          //   url: res.data[0].cameSn,
          // };
        });
      },
      getVideoList(deviceSn, type) {
        screen.getListCamera({ deviceSn: deviceSn }).then((res) => {
          this.videoList = res.data;
          this.activeName = res.data[0].cameSn;
          this.videoInfo = {
            type: res.data[0].cameraType,
            url: res.data[0].cameSn,
          };
        });
      },
  
      getDeviceParam(deviceId) {
        let params = {
          deviceId: deviceId,
          paramLable: "workset",
        };
        screen
          .deviceParam(params)
          .then((res) => {
            let jsonData = JSON.parse(res.data.paramContent);
  
            this.workData = jsonData.workset;
          })
          .catch((err) => {});
      },
      updateListByType(data, type) {
        data.map((item) => {
          if (item.alarmLevel >= 12) {
            this.list.find((item2) => item2.type === type).show = true;
          }
        });
      },
      getSelectDeviceAlarm(deviceImei) {
        const typeMap = {
          amplitude: "amplitude",
          weight: "weight",
          moment: "moment",
          anticollision: "anticollision",
          barrier: "barrier",
          elevation: "elevation",
          height: "height",
          rotate: "rotate",
          walk: "walk",
          wind: "wind",
          // Add more types as needed
        };
        screen.selectDeviceAlarm({ deviceImei: deviceImei }).then((res) => {
        
          for (const key in typeMap) {
            if (res.data[key]) {
              this.updateListByType(res.data[key], typeMap[key]);
            }
          }
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .container {
    background-image: url("../../../assets/images/screen/newbgdel.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    background-position: center;
    padding: 0 20px;
    position: relative;
    overflow: auto;
  }
  .heard {
    height: 6%;
    // border: 1px solid red;
  }
  .main {
    height: 94%;
    // border: 1px solid red;
    display: flex;
  }
  .leftMain {
    width: 79%;
    height: 100%;
    margin-right: 1%;
    // border: 1px solid red;
    .leftTop {
      height: 20%;
      // border: 1px solid red;
      .realBox {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .leftModel {
      height: 50%;
      // border: 1px solid red;
      display: flex;
      .leftdel {
        width: 49%;
        height: 100%;
        overflow: auto;
        // border: 1px solid red;
        overflow: auto;
        .simulationBg {
          background-image: url("../../../assets/images/screen/newTitleBox.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 8%;
  
          background-position: center;
          color: #ffffff;
          padding-left: 20px;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          padding-right: 20px;
        }
        .henxian {
          height: 6%;
          color: #ffffff;
          // border: 1px solid red;
          .box8 {
            display: flex;
            justify-content: space-between;
            position: relative;
            // align-items: end;
            height: 94%;
            .box9 {
              display: flex;
              .title {
                margin-left: 10px;
              }
            }
            .box10 {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .henxianbox {
            background-image: url("../../../assets/images/screen/hengxian.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 6%;
            // border: 1px solid red;
          }
        }
        .simulatedBox {
          height: 74%;
          overflow: auto;
        }
        .warnBoxsc {
          height: 18%;
          // border: 1px solid red;
          background-image: url("../../../assets/images/screen/userInfo.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          overflow: hidden;
          display: flex;
          padding: 0 20px;
          align-items: center;
          flex-wrap: wrap;
          .singular {
            width: 20%;
          }
          .showBox {
            color: #ffffff;
          }
          .showBox2 {
            color: red;
          }
          .test {
            display: flex;
            // border: 1px solid red;
          }
        }
      }
      .leftdel2 {
        width: 49%;
        height: 100%;
        margin-left: 2%;
        // border: 1px solid red;
        .videoTitleBox {
          background-image: url("../../../assets/images/screen/newTitleBox.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 8%;
          // margin-bottom: 3%;
          background-position: center;
          color: #ffffff;
          padding-left: 20px;
        }
        .henxian {
          height: 6%;
          color: #ffffff;
          // border: 1px solid red;
          .box8 {
            display: flex;
            justify-content: space-between;
            position: relative;
            // align-items: end;
            height: 94%;
            .box9 {
              display: flex;
              .title {
                margin-left: 10px;
              }
            }
            .box10 {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .henxianbox {
            background-image: url("../../../assets/images/screen/hengxian.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 6%;
            // border: 1px solid red;
          }
        }
        .tabs {
          height: 10%;
        }
        .videoss {
          // margin-top: 3%;
          height: 82%;
          // padding: 16px;
          overflow: auto;
        }
      }
    }
    .leftBottom {
      height: 30%;
      // border: 1px solid yellow;
      display: flex;
      .leftBottomdel {
        width: 100%;
        height: 100%;
        .simulationBg {
          background-image: url("../../../assets/images/screen/newTitleBox.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 14%;
  
          background-position: center;
          color: #ffffff;
          padding-left: 20px;
        }
        .henxian {
          height: 12%;
          color: #ffffff;
          // border: 1px solid red;
          .box8 {
            display: flex;
            justify-content: space-between;
            position: relative;
            // align-items: end;
            height: 94%;
            .box9 {
              display: flex;
              .title {
                margin-left: 10px;
              }
            }
            .box10 {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .henxianbox {
            background-image: url("../../../assets/images/screen/hengxian.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 6%;
            // border: 1px solid red;
          }
        }
        // border: 1px solid red;
        .realDatadetailbox {
          height: 88%;
        }
      }
      .leftBottomdel2 {
        width: 49%;
        margin-left: 2%;
        height: 100%;
        // border: 1px solid red;
        .simulationBg {
          background-image: url("../../../assets/images/screen/newTitleBox.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 100%;
  
          background-position: center;
          color: #ffffff;
          padding-left: 20px;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          padding-right: 20px;
        }
        .henxian {
          height: 12%;
          color: #ffffff;
          // border: 1px solid red;
          .box8 {
            display: flex;
            justify-content: space-between;
            position: relative;
            // align-items: end;
            height: 94%;
            .box9 {
              display: flex;
              .title {
                margin-left: 10px;
              }
            }
            .box10 {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .henxianbox {
            background-image: url("../../../assets/images/screen/hengxian.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 6%;
            // border: 1px solid red;
          }
        }
        .workSetData {
          height: 88%;
          // margin-top: 10%;
        }
      }
    }
  }
  .rightMain {
    // margin-left: 2%;
    width: 19%;
    height: 100%;
    // border: 1px solid red;
    .rightTop {
      height: 40%;
      // border: 1px solid red;
    }
    .rightBottom {
      height: 60%;
      // border: 1px solid red;
      .simulationBg {
        background-image: url("../../../assets/images/screen/newTitleBox.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        // width: 140%;
        background-position: center;
        color: #ffffff;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding-right: 20px;
      }
      .henxian {
        height: 5%;
        color: #ffffff;
        // border: 1px solid red;
        .box8 {
          display: flex;
          justify-content: space-between;
          position: relative;
          // align-items: end;
          height: 94%;
          .box9 {
            display: flex;
            .title {
              margin-left: 10px;
            }
          }
          .box10 {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .henxianbox {
          background-image: url("../../../assets/images/screen/hengxian.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 6%;
          // border: 1px solid red;
        }
      }
      .warndetail {
        height: 95%;
      }
    }
  }
  /deep/.el-tabs__item {
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    position: relative;
  }
  /deep/.el-tabs__item.is-active {
    color: #409eff; /* Default text color for unselected tabs */
  }
  </style>
  