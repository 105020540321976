
<template>
 
    <el-drawer
    :size="size"
  :title="title"
  :before-close="handleClose"
  :visible.sync="visible"
  direction="ltr"
  custom-class="demo-drawer custom-width"
  ref="drawer"
  >
  <slot></slot>
</el-drawer>


</template>

<script>
export default {
    props:['visible','title','size'],
 components:{

 },
 data () {
  return {

  }
 },
 mounted(){

 },
 methods:{
    handleClose(){
        this.$emit('handleClose')
    }
 },
}

</script>

<style lang='less' scoped>
/deep/.el-drawer {
    position: absolute;
    box-sizing: border-box;
    background-color: #072640;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
    overflow: hidden;
    outline: 0;
}
/deep/.el-drawer__close-btn {
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    background-color: transparent;
}
/deep/.el-drawer__header {
    align-items: center;
    color: #fff;
    display: flex;
    margin-bottom: 32px;
    padding: 20px 20px 0;
}

</style>
