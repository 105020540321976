<template>
  <div class="headDeil">
    <div class="bgdels">
      <div class="bgdelLeft">
        <div class="title">{{ deviceNo }}</div>
        <div>监控主机：{{ title }}</div>
        <div class="iconBox" v-if="statusOnline == 'online'">
          <img src="../../../assets/images/screen/newonlie.png" alt="" />
          <span class="marinLeft">在线</span>
        </div>
        <div class="iconBox" v-if="statusOnline == 'offline'">
          <img src="../../../assets/images/screen/off.png" alt="" />
          <span class="marinLeft">离线</span>
        </div>
        <div class="iconBox">
          <img src="../../../assets/images/screen/newnormal.png" alt="" />
          <span class="marinLeft">正常</span>
        </div>
        <div class="iconBox">
          <img src="../../../assets/images/screen/newearly.png" alt="" />
          <span class="marinLeft">预警</span>
        </div>
        <div class="iconBox">
          <img src="../../../assets/images/screen/newpolice.png" alt="" />
          <span class="marinLeft">报警</span>
        </div>
        <div class="cycle" @click="warnReodes" v-if="deviceType!= 8">告警记录</div>
        <div class="cycle" @click="cycle" v-if="deviceType!= 8">工作循环</div>
        <el-dropdown class="iconBox" @command="handleCommand" v-if="deviceType!= 8">
          <span class="el-dropdown-link">
            历史记录<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="d">历史记录</el-dropdown-item>
            <!-- <el-dropdown-item command="e">上下线历史</el-dropdown-item> -->
            <el-dropdown-item command="f">打卡记录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="iconBox" @command="handleCommand"  v-if="deviceType!= 8">
          <span class="el-dropdown-link">
            设定<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">工况设置</el-dropdown-item>
            <el-dropdown-item command="b" v-if="deviceType==0">起重特性表设置</el-dropdown-item>
            <el-dropdown-item command="c" >远程标定</el-dropdown-item>
            <el-dropdown-item command="g" v-if="deviceType==0">群塔防碰撞设置</el-dropdown-item>
            <el-dropdown-item command="h" v-if="deviceType==0">阈值设置</el-dropdown-item>
          </el-dropdown-menu>
          
        </el-dropdown>
      </div>
      <div class="bgdelRight">
        <!-- <div class="boderbg">
          <img src="../../../assets/images/screen/time.png" alt="" />
          <div class="timeTitle">上线时间:{{ onLineTime }}</div>
        </div> -->
        <div class="boderbg workTime">
          <img src="../../../assets/images/screen/time.png" alt="" />
          <div class="timeTitle">工作循环时长:{{ days }}</div>
        </div>
        <div class="back" @click="back">返回</div>
      </div>
    </div>

    <!-- <Workset :visible.sync="showDialog" @handleClose="handleClose" :workData="workData" :versioncc="versioncc" @rest="rest"></Workset> -->
    <workSetClassification
      :visible.sync="showDialog"
      @handleClose="handleClose"
      :workData="workData"
      :versioncc="versioncc"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
    ></workSetClassification>
    <momentClassifcation
    v-if="deviceType==0"
      :visible.sync="showDialogMoment"
      @handleClose="handleCloseMoment"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
    ></momentClassifcation>
    <historyClassification
      :visible.sync="showDialogHistory"
      @handleClose="handleCloseHistory"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
     
    ></historyClassification>
    <calibrationClassification
      :visible.sync="showDialogCalibration"
      @handleClose="handleCloseCalibration"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :realTimeData="realTimeData"
    ></calibrationClassification>
    <driverHistory
      :visible.sync="showDialogDriver"
      @handleClose="handleCloseDriver"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :realTimeData="realTimeData"
    ></driverHistory>
    <cycleWorkClassification :visible.sync="showDialogcycle"
      @handleClose="handleClosecycle"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData">

    </cycleWorkClassification>
    <!-- <cycleWork
      :visible.sync="showDialogcycle"
      @handleClose="handleClosecycle"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
    ></cycleWork> -->
    <alarmLog
    v-if="deviceType==0"
      :visible.sync="showDialogWarn"
      @handleClose="handleCloseWarn"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
    ></alarmLog>
    <LiftAlarmLog
    v-if="deviceType==1"
      :visible.sync="showDialogWarn"
      @handleClose="handleCloseWarn"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
    ></LiftAlarmLog>
    <doorAlarmLog
    v-if="deviceType==2"
      :visible.sync="showDialogWarn"
      @handleClose="handleCloseWarn"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
    ></doorAlarmLog>
    <bridgeAlarmLog
    v-if="deviceType==3"
      :visible.sync="showDialogWarn"
      @handleClose="handleCloseWarn"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
    ></bridgeAlarmLog>
    <TowerCollision
    v-if="deviceType==0&&showDialogTowerCollision==true"
      :visible.sync="showDialogTowerCollision"
      @handleClose="handleTowerCollision"
     :svgTimes="svgTimes"
      :deviceType="deviceType"
      :selfDevice="selfDevice"
     :statusArr="statusArr"
      :title="title"
      
      :statusDatas="statusDatas"
    ></TowerCollision>
    <thresholdClassification
    :visible.sync="showDialogTowerthreshold"
      @handleClose="handleCloseTowerthreshold"
      @rest="rest"
      :deviceType="deviceType"
      :title="title"
      :workData="workData"
      :realTimeData="realTimeData"
    ></thresholdClassification>
  </div>
</template>

<script>
import screen from "../../../api/modules/screen";
import workSetClassification from "./classification/workSetClassification.vue";
import momentClassifcation from "./classification/momentClassifcation.vue";
import historyClassification from "./classification/historyClassification.vue";
import calibrationClassification from "./classification/calibrationClassification.vue";
import thresholdClassification from "./classification/thresholdClassification.vue";
import cycleWorkClassification from "./classification/cycleWorkClassification.vue";
import driverHistory from "./driverHistory";
import TowerCollision from './TowerCollision'
import cycleWork from "./cycleWork";
import alarmLog from "./alarmLog";
import LiftAlarmLog from "./LiftAlarmLog";
import doorAlarmLog from "./doorAlarmLog";
import bridgeAlarmLog from "./bridgeAlarmLog";
import { toDuration } from "../../../utils/tool";
import { createConnect, endConnect } from "../../../api/mqtt";
export default {
  props: [
    "title",
    "deviceOnline",
    "deviceNo",
    "workData",
    "versioncc",
    "deviceType",
    "realTimeData",
    "showWarm",
    "totalTime",
    "svgTimes",
    "selfDevice"
  ],
  components: {
    workSetClassification,
    momentClassifcation,
    historyClassification,
    calibrationClassification,
    driverHistory,
    cycleWork,
    alarmLog,
    LiftAlarmLog,
    doorAlarmLog,
    bridgeAlarmLog,
    cycleWorkClassification,
    TowerCollision,
    thresholdClassification
  },
  data() {
    return {
      onLine: "",
      timer: null,
      onLineTime: "",
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
      dialog: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "80px",
    
      timer: null,
      loading: false,
      showDialog: false,
      showDialogMoment: false,
      showDialogHistory: false,
      showDialogCalibration: false,
      showDialogDriver: false,
      showDialogcycle: false,
      showDialogWarn: false,
      showDialogTowerCollision:false,
      showDialogTowerthreshold:false,
      statusOnline:'',
      statusArr:[],
      statusDatas:'',
      timer:null
 
    };
  },
  mounted() {

  this.timer = setInterval(()=>{
  this.status()
      this.selfStatus()
 },2000)
   
  },

  watch: {
    totalTime(val) {
      this.days = toDuration(val);
      // this.getStatus()
    },
    realTimeData(val){
     
      // this.getStatus()
    },
    showWarm(val){
      if(val==true){
        this.showDialogWarn = true;
      }
    }
  
  },
  beforeDestroy() {
    // 清除定时器
    // clearInterval(this.timer);
    // clearInterval(this.timer2);
    clearInterval( this.timer)
    endConnect();
  },
  methods: {
    status(){
      let params = {
        deviceImei: this.title,
        termTopic: "vicinityStatus",
      };
      screen.getTerm(params).then((res) => {
        if (res.data.length > 0) {
          let data = JSON.parse(res.data[0]?.termData);
          this.statusArr = data.neighbours
        }
      });
    },
    selfStatus(){
      let params = {
        deviceImei: this.title,
        termTopic: "Status",
      };
      screen.getTerm(params).then((res) => {
        if (res.data.length > 0) {
          let data = JSON.parse(res.data[0]?.termData);
     
        this.statusDatas = data.status[0].status
        
        }
      });
    },
     //订阅在线实时记录
     getStatus(){
      let that = this;
    
     
      createConnect(
        `F/+/+/+/+/+/+/+/+/${this.title}/Status`,

        function (topic, message) {
          // 在这里处理从 MQTT 收到的消息
          console.log("收到来自", topic);
          console.log("状态", message);
          that.statusOnline = message.status[0].status
          console.log('statusOnline', that.statusOnline);
          
        }
      );
    },
    handleClose() {
      this.showDialog = false;
    },
    handleCloseMoment() {
      this.showDialogMoment = false;
    },
    handleCloseHistory() {
      this.showDialogHistory = false;
    },
    handleCloseCalibration() {
      this.showDialogCalibration = false;
    },
    handleCloseDriver() {
      this.showDialogDriver = false;
    },
    handleClosecycle() {
      this.showDialogcycle = false;
    },
    handleCloseWarn() {
      this.showDialogWarn = false; 
    },
    handleTowerCollision(){
      this.showDialogTowerCollision=false
      let loading = this.$loading();
      loading.close();
    },
    handleCloseTowerthreshold(){
      this.showDialogTowerthreshold=false
    },
    cycle() {
      this.showDialogcycle = true;
    },

    warnReodes() {
      this.showDialogWarn = true;
    },
    rest() {
      this.$emit("rests");
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    getDeviceOnlie(deviceImei) {
      screen.selectOnline(deviceImei).then((res) => {
        this.onLine = res.data.deviceOnline;
      });
    },
    getSelectDeviceTime(deviceImei) {
      screen.selectDeviceTime(deviceImei).then((res) => {
        this.onLineTime = res.data.onLineTime;
        // Timestamps in milliseconds
        const startTime = new Date(res.data.startTime).getTime();
        const endTime = new Date(res.data.endTime).getTime();

        // Calculate the difference in milliseconds
        let timeDiff = endTime - startTime;

        // Calculate days, hours, minutes, and seconds
        this.seconds = Math.floor(timeDiff / 1000);
        this.minutes = Math.floor(this.seconds / 60);
        this.hours = Math.floor(this.minutes / 60);
        this.days = Math.floor(this.hours / 24);

        this.hours %= 24;
        this.minutes %= 60;
        this.seconds %= 60;
      });
    },
    back(){
      this.$router.go(-1);
    },
    handleCommand(command) {
      const commandActions = {
        a: () => {
          this.showDialog = true;
        },
        b: () => {
          this.showDialogMoment = true;
        },
        c: () => {
          // 添加对应的操作
          this.showDialogCalibration = true;
        },
        d: () => {
          // 添加对应的操作
          this.showDialogHistory = true;
        },
        f: () => {
          this.showDialogDriver = true;
        },
        g: () => {
          this.showDialogTowerCollision=true
        },
        h: () => {
          this.showDialogTowerthreshold=true
        },
      };

      const action = commandActions[command];
      if (action) {
        action();
      } else {
        console.error("Unknown command:", command);
         
      }
    },
  },
};
</script>

<style lang="less" scoped>
.headDeil {
  height: 100%;
}
.bgdels {
  background-image: url("../../../assets/images/screen/newheads.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  //   line-height: 55px;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boderbg {
   // background-image: url("../../assets/images/screen/boder.png");
   background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  //   width: 240px;
  font-size: 14px;
  // padding: 7px 15px;
  // margin-top: 5px;
  //   line-height: 55px;
  padding: 0 15px;
  color: #78f0fd;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgdelLeft {
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: space-between;
}
.marinLeft {
  margin-left: 12px;
  font-size: 14px;
}
.iconBox {
  margin-left: 19px;
  display: flex;
  align-items: center;
}
.title {
  margin-right: 15px;
  font-size: 18px;
}
.bgdelRight {
  display: flex;
  height: 100%;
}
.timeTitle {
  margin-left: 6px;
}
.workTime {
  margin-left: 21px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
/deep/.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 10px 0;
  margin: 5px 0;
  background-color: #062038 !important;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.cycle {
  margin-left: 14px;
  font-size: 14px;
}
.cycle:hover {
  margin-left: 14px;
  font-size: 14px;
  cursor: pointer;
}
.back{
  cursor: pointer;
  color: #78f0fd;
  display: flex;
  align-items: center;
}
</style>
