<!--  -->
<template>
  <drawerDialog :visible.sync="visible" @handleClose="handleClose" title="工况"  >
    <div class="demo-drawer__content">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          label="塔吊类型"
          :label-width="formLabelWidth"
          prop="type"
        >
          <el-select v-model="form.type" placeholder="请选择塔吊类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="主臂长度"
          :label-width="formLabelWidth"
          prop="armlen"
        >
          <el-input
            placeholder="请输入主臂长度"
            v-model="form.armlen"
            type="number"
          >
            <template slot="append">米</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="首节高度"
          :label-width="formLabelWidth"
          prop="fristsection"
        >
          <el-input
            placeholder="请输入首节高度"
            v-model="form.fristsection"
            type="number"
          >
            <template slot="append">米</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="节高"
          :label-width="formLabelWidth"
          prop="sectionhigh"
        >
          <el-input
            placeholder="请输入节高"
            v-model="form.sectionhigh"
            type="number"
          >
            <template slot="append">米</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="节数"
          :label-width="formLabelWidth"
          prop="sectionnum"
        >
          <el-input
            placeholder="请输入节数"
            v-model="form.sectionnum"
            type="number"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="倍率" :label-width="formLabelWidth" prop="rate">
          <el-input placeholder="请输入倍率" v-model="form.rate" type="number">
          </el-input>
        </el-form-item>
        <el-form-item
          label="主臂宽度"
          :label-width="formLabelWidth"
          prop="armwidth"
        >
          <el-input
            placeholder="请输入主臂宽度"
            v-model="form.armwidth"
            type="number"
          >
            <template slot="append">米</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="安全距离"
          :label-width="formLabelWidth"
          prop="safelen"
        >
          <el-input
            placeholder="请输入安全距离"
            v-model="form.safelen"
            type="number"
          >
            <template slot="append">米</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </drawerDialog>
</template>

<script>
import drawerDialog from "../../../components/common/drawerDialog";
import { createConnect, endConnect } from "../../../api/mqtt";
import mqtt from "mqtt";
import { demandPublish } from '../../../utils/index';
export default {
  props: ["visible", "workData", "versioncc","title"],
  components: {
    drawerDialog,
  },
  data() {
    return {
      form: {},
      formLabelWidth: "80px",
      timer: null,
      loading: false,
      rules: {
        type: [
          { required: true, message: "请选择塔吊类型", trigger: "change" },
        ],

        armlen: [
          { required: true, message: "请输入主臂长度", trigger: "blur" },
        ],
        fristsection: [
          { required: true, message: "请输入首节高度", trigger: "blur" },
        ],

        sectionhigh: [
          { required: true, message: "请输入节高", trigger: "blur" },
        ],
        sectionnum: [
          { required: true, message: "请输入节数", trigger: "blur" },
        ],
        rate: [{ required: true, message: "请输入倍率", trigger: "blur" }],
        armwidth: [
          { required: true, message: "请输入主臂宽度", trigger: "blur" },
        ],
        safelen: [
          { required: true, message: "请输入安全距离", trigger: "blur" },
        ],
      },
      loading:false,
      versionccs: "",
      options: [
        { label: "平头", value: "crop" },
        { label: "尖头", value: "cusp" },
      ],
    };
  },
  mounted() {},
  watch: {
    workData(val) {
      console.log("val", val);
      this.form = val;
    },
    versioncc(val) {
      this.versionccs = val;
    },
    // visible(val){
    //   this.form = val;
    // }
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
      onSubmit() {
      this.$refs["ruleForm"].validate(async(valid) => {
        if (valid) {
          let workset = [];
          workset.push(this.form);
          let workSetDataMqtt = {
              command: "CfgWorkset",
              workset: workset,
             
            };
            let loading = this.$loading();
            this.mqttSubmit = demandPublish(this.title, workSetDataMqtt);
            let res = await this.mqttSubmit.publish();
            loading.close();
            if (res.command != 'Succeed') {
          this.$message('保存失败');
          return;
        }
        this.$message('保存成功');
        this.$emit("rest");
        // this.onChange();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
</style>
