import mqtt from "mqtt";
let clientServer = null,
  clientRequest = null;
function createConnect() {
  clientServer = mqtt.connect(`ws://106.15.63.225:8085`, {});
  clientRequest = mqtt.connect(`ws://106.15.63.225:8083`, {
    username: "keda",
    password: "@xyz19930211a",
  });
  console.log("clientServer", clientServer);
}
function endConnect() {
  clientServer?.end(true, function () {
    console.log("clientServer已关闭");
  });
  clientServer = null;
  clientRequest?.end(true, function () {
    console.log("clientRequest已关闭");
  });
  clientRequest = null;
}
createConnect();
export default {
  get clientServer() {
    return clientServer;
  },
  get clientRequest() {
    return clientRequest;
  },
  createConnect,
  endConnect,
};
