<template>
      <component :is="vidoTYpeObj[data.type]"
               v-if="data.url"
               :url="data.url"
               :channel="data.channel || 1"
               :index="index"
               :width="width"
               :height="height">
    </component>
    
  </template>
  
  <script>
  import BowaVideo from "../BowaVideo.vue";
  import YinshiVideo from "../YinshiVideo";
  import ZiyouVideo from "../ZiyouVideo";
  export default {
    name: "index",
    props: ['data', 'index','width','height'],
    components: {
    
      BowaVideo,
      ZiyouVideo,
      YinshiVideo,
    },
    watch:{
      data(val){
        console.log(val,'11111111');
  
     
      }
    },
    data() {
      return {
        MonitorSwitchpageOR: '',
        vidoTYpeObj:{
          0:'YinshiVideo',
          2: 'BowaVideo',
          1: 'ZiyouVideo',
        },
      };
    },
  }
  </script>
  
  <style scoped>
  
  </style>