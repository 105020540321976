<template>
    <component :is="vidoTYpeObj[deviceType]"  :visible.sync="visible" @handleClose="handleClose" :workData="workData" @rest="rest" :title="title" :deviceType="deviceType"> </component>
  </template>
  
  <script>
import cycleWork from '../cycleWork.vue'
import doorcycleWork from '../doorcycleWork.vue'
  export default {
    name: "index",
    props: ["visible", "workData", "versioncc","deviceType","title",'deviceType'],
    components: {
      cycleWork,
      doorcycleWork
      
    },
    watch: {
      data(val) {
        console.log(val);
      },
    },
    data() {
      return {
        MonitorSwitchpageOR: "",
        vidoTYpeObj: {
          0: "cycleWork",
          2: "doorcycleWork",
        },
      };
    },
    methods:{
        handleClose() {
      this.$emit("handleClose");
    },
    rest(){
        this.$emit("rest");
    }
    }
  };
  </script>
  
  <style scoped></style>
  