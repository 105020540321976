<template>
    <component :is="vidoTYpeObj[deviceType]" :title="title" :equmentObj="equmentObj"  :realTimeData="realTimeData" :workData="workData" :svgTimes="svgTimes"> </component>
  </template>
  
  <script>
  import tower from "./tower.vue";
  import lift from './lift.vue'
  import bridge from "./bridge.vue";
  import door from "./door";
  import Fugitive from "./Fugitive";
  export default {
    name: "index",
    props: ["deviceType", "title", "width", "height",'realTimeData','workData','svgTimes',"equmentObj"],
    components: {
        tower,
        lift,
        door,
      bridge,
      Fugitive
    },
    watch: {
      data(val) {
        console.log(val,'0000');
      },
    },
    data() {
      return {
        MonitorSwitchpageOR: "",
        vidoTYpeObj: {
          0: "tower",
          1: "lift",
          2:'door',
          3: "bridge",
          8:'Fugitive'
        },
      };
    },
  };
  </script>
  
  <style scoped></style>
  