<template>
    <component :is="vidoTYpeObj[deviceType]"  :visible.sync="visible" @handleClose="handleClose"  @rest="rest" :title="title" :workData="workData" :deviceType="deviceType"> </component>
  </template>
  
  <script>
import towerHistoryData from '../towerHistoryData.vue'
import liftHistoryData from '../liftHistoryData.vue'
import doorHistoryData from '../doorHistoryData.vue'
import bridgeHistoryData from '../bridgeHistoryData.vue'

  export default {
    name: "index",
    props: ["visible","deviceType","title","workData"],
    components: {
        towerHistoryData,
        liftHistoryData,
        doorHistoryData,
        bridgeHistoryData
    },
    watch: {
      data(val) {
        console.log(val);
      },
    },
    data() {
      return {
        MonitorSwitchpageOR: "",
        vidoTYpeObj: {
          0: "towerHistoryData",
          1: "liftHistoryData",
          2: "doorHistoryData",
          3:'bridgeHistoryData'
        },
      };
    },
    methods:{
        handleClose() {
      this.$emit("handleClose");
    },
    rest(){
        this.$emit("rest");
    }
    }
  };
  </script>
  
  <style scoped></style>
  