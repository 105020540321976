<template>
  <div class="realData">
    <!-- <div class="titleBoxs">
        可视化数据分析
    </div> -->
    <div class="main-box">
      <div class="chart-box" ref="monthWarn"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getStateData } from "../../../components/screen/chartOption";

import { turnTimes,turnDayTime } from "../../../utils/tool";
export default {
  components: {},
  props: ["realTimeData",'times'],
  data() {
    return {
      chartMonthWarn: null,
      timer: null,
      time: [],
      wind: [],
      momentratio: [],
      height: [],
      weight: [],
      travel: [],
    };
  },
  watch: {
    realTimeData: {
      handler(val) {
        if (val) {
          this.initCarTrendChar(val);
        
        }
      },
    },
  },

  mounted() {
    this.initCarTrendChar(0);
  },
  methods: {
    async initCarTrendChar(realTimeData) {
      if(realTimeData){
        if (this.time.length > 15) {
     
          
        this.time= this.time.slice(10);
       this.wind=  this.wind.slice(10);
        this.momentratio=this.momentratio.slice(10);
       this.height= this.height.slice(10);
       this.weight= this.weight.slice(10);
        this.travel=this.travel.slice(10);
      }
          this.time.push(turnDayTime(this.times))
        this.wind.push(realTimeData.wind)
        this.momentratio.push( realTimeData.momentratio)
        this.height.push( realTimeData.height)
        this.weight.push(realTimeData.load)
        this.travel.push(realTimeData.travel)
        if (!this.chartMonthWarn) {
        this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
      }
     
      const option = getStateData(
          this.wind,
        this.momentratio,
        this.height,
        this.weight,
        this.travel,
        this.time
      );

      this.chartMonthWarn.setOption(option);
      }else{
        if (this.time.length > 15) {
     
          
     this.time= this.time.slice(10);
    this.wind=  this.wind.slice(10);
     this.momentratio=this.momentratio.slice(10);
    this.height= this.height.slice(10);
    this.weight= this.weight.slice(10);
     this.travel=this.travel.slice(10);
   }
       this.time.push(turnDayTime(0))
     this.wind.push(0)
     this.momentratio.push(0)
     this.height.push( 0)
     this.weight.push(0)
     this.travel.push(0)
     if (!this.chartMonthWarn) {
     this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
   }
  
   const option = getStateData(
       this.wind,
     this.momentratio,
     this.height,
     this.weight,
     this.travel,
     this.time
   );

   this.chartMonthWarn.setOption(option);
      }
      
   

  
    },
  },
};
</script>

<style lang="less" scoped>
.realData {
  height: 100%;
  .titleBoxs {
    background-image: url("../../../assets/images/screen/newTitleBox.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:10%;

    background-position: center;
    color: #FFFFFF;
    padding-left: 20px;
  //  margin-bottom: 10px;
    
  }
  .main-box {
    height: 100%;
    width: 100%;
    .chart-box {
      width: 100%;
     height: 100%;
     
    }
  }
}
</style>
